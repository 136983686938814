import { gql } from "@apollo/client";

export const PROFILE_EDIT = gql`
  mutation userProfileEditMutation($userId: Int!, $input: UserInput!) {
    userProfileEdit(input: $input, userId: $userId) {
      success
    }
  }
`;

export const REGISTRATION = gql`
  mutation registrationMutation($input: UserInput!) {
    userRegistration(input: $input) {
      success
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPasswordMutation($mail: String!) {
    userForgotPass(mail: $mail) {
      success
    }
  }
`;

export const USER_CONFIRM_PASSWORD = gql`
  mutation userConfirmPassMutation(
    $hash: String!
    $timestamp: Int!
    $userId: Int!
  ) {
    userConfirmPass(hash: $hash, timestamp: $timestamp, userId: $userId) {
      username
      thumbnail
      role
      residence
      picture
      phone
      mail
      lastName
      jmbg
      id
      firstName
      birthDate
      biography
    }
  }
`;

export const USER_CONFIRM_MAIL = gql`
  mutation userConfirmMailMutation(
    $hash: String!
    $timestamp: Int!
    $userId: Int!
  ) {
    userConfirmMail(hash: $hash, timestamp: $timestamp, userId: $userId) {
      success
    }
  }
`;

export const USER_RESET_PASSWORD = gql`
  mutation userResetPassMutation(
    $userId: Int!
    $password: String!
    $passwordAgain: String!
  ) {
    userResetPass(
      password: $password
      passwordAgain: $passwordAgain
      userId: $userId
    ) {
      success
    }
  }
`;

export const USER_LOGIN = gql`
  mutation userLogin($input: UserLogin!) {
    userLogin(input: $input) {
      id
      mail
      username
      firstName
      lastName
      fathersName
      fullNameSr
      birthDate
      jmbg
      residence
      zipCode
      streetAddress
      streetAddressSr
      placeOfBirth
      phone
      picture
      thumbnail
      role
      biography
      hunElementarySchool
      hunHighSchool
      completedHighSchool
    }
  }
`;

export const ENTITY_FIELDS_MUTATION = gql`
  mutation EntityFieldsMutation(
    $input: [FieldInput!]
    $id: String
    $entityType: String
    $bundle: String
  ) {
    setEntityFields(
      input: $input
      id: $id
      entityType: $entityType
      bundle: $bundle
    ) {
      success
      errors {
        message
        propertyPath
        value
        parentId
      }
      id
    }
  }
`;

export const UPLOAD_ENTITY = gql`
  mutation UploadFile(
    $file: Upload!
    $fieldName: String!
    $entityType: String
    $bundle: String
  ) {
    uploadFile(
      file: $file
      fieldName: $fieldName
      entityType: $entityType
      bundle: $bundle
    ) {
      url
      error
      id
    }
  }
`;

export const DELETE_ENTITY = gql`
  mutation DeleteNode($id: Int!, $type: String = "node") {
    deleteEntity(id: $id, type: $type) {
      success
      errors {
        message
        propertyPath
        value
      }
      id
    }
  }
`;

export const FLAG_ENITTY = gql`
  mutation FlagEntity($flagName: String!, $entityId: Int!) {
    flagEntity(flagName: $flagName, entityId: $entityId) {
      success
      errors {
        message
        propertyPath
        value
      }
      id
    }
  }
`;
