import { InMemoryCache } from "@apollo/client";
import { ApolloClient } from "apollo-client";
import { createUploadLink } from "apollo-upload-client";

export function createClient() {
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
      uri: `${process.env.REACT_APP_DRUPAL_URL}/graphql`,
      credentials: "include",
    }),
  });
  return client;
}
