import { useForm } from "react-hook-form";
import { InputField, PrimaryButton, Alert, Field } from "../../components/Elements/elements";
import { Fields } from "./FormFields";
import { Card, CardContent } from "@material-ui/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import { USER_LOGIN } from "../../graphql/Schemas/Mutations/Mutations";
import { useMutation } from "@apollo/client";
import { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../../utils/UserContext";
import { useGoogleReCaptcha, GoogleReCaptcha } from "react-google-recaptcha-v3";
import ConfigInfo from "../../components/ConfigInfo/ConfigInfo";
import { isKelep } from "../../constants";

export const LoginPage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [Login] = useMutation(USER_LOGIN, { errorPolicy: "all" });
  const { state } = useLocation();
  const history = useHistory();
  const [message, setMessage] = useState(state?.message);
  const [token, setToken] = useState();
  const { setUser } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "all",
    criteriaMode: "all",
  });

  const onSubmit = dataForm => {
    if (token)
      Login({
        variables: {
          input: dataForm,
        },
      }).then(({ data, errors }) => {
        if (data.userLogin !== null) {
          setUser(data["userLogin"]);
        } else {
          setMessage(errors[0].message);
        }
      });
  };
  const close = () => {
    setMessage();
    history.replace();
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (executeRecaptcha) {
      const token = await executeRecaptcha("yourAction");
      setToken(token);
    }
  }, []);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  // @todo Add this:
  // @see https://gitlab.studiopresent.com/drupal8/mnb/-/blob/dev/app/react_frontend/src/screens/RegistrationPage/RegistrationPage.js
  /*
  <ConfigInfo
    type="loginText"
  />
  */

  return (
    <>
      {!isKelep && (
        <div className="report">
          <ConfigInfo type="loginText" string={true} />
        </div>
      )}
      <Card className="login-form-wrapper">
        <CardContent>
          <h2 className="breki-title">Bejelentkezés</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="login-form">
              {Fields.map(({ type, label, name }, idx) => {
                return (
                  <Field
                    field={{ label }}
                    error={errors?.[name]?.message}
                    value={
                      <InputField
                        key={idx}
                        inputProps={{ ...register(`${name}`) }}
                        type={type}
                        placeholder={label}
                        error={!!errors[name]}
                      />
                    }
                  />
                );
              })}
              <div className="login-form-button">
                <PrimaryButton type="submit" disabled={Object.keys(errors).length} text="Bejelentkezés" />
              </div>

              <div className="form-links">
                <div>
                  Új felhasználó? <Link to="/regisztracio">Regisztráció</Link>
                </div>
                <div>
                  <Link to="/elfelejtett-jelszo">Elfelejtett jelszó?</Link>
                </div>
              </div>
            </div>
          </form>
          <GoogleReCaptcha onVerify={setToken} />
          {message && <Alert text={message} color={state?.type ? state.type : "error"} close={close} />}
        </CardContent>
      </Card>
    </>
  );
};
