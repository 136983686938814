import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { CheckBox } from "../elements";

export const CheckBoxes = ({ field, control , disabled }) => {
  const allowedValues = field.value?.reduce((results, { key }) => {
    return { ...results, [key]: true };
  }, {});
  return (
    <Controller
      render={({ field: { onChange, value: fieldValue, ref } }) => (
        <div ref={ref} tabIndex="-1" className="outline-none">
          <FormControl
            required
            //   error={error}
            component="fieldset"
            onChange={(e) => {
              if (field.type == "radios") {
                onChange(e.target.value);
              } else {
                if (fieldValue.split(",").includes(e.target.value)) {
                  onChange(
                    fieldValue
                      .split(",")
                      .filter((el) => el !== e.target.value)
                      .join(",")
                  );
                } else {
                  onChange(
                    (fieldValue == "" ? "" : fieldValue + ",") + e.target.value
                  );
                }
              }
            }}
          >
            {field.allowedValues.map(({ key, value }) => (
              <div>
                {(() => {
                  if (field.type == "radios") {
                    return (
                      <RadioGroup name={field.name} value={fieldValue}>
                        <FormControlLabel
                          value={key}
                          control={
                            <Radio disabled={disabled} style={{ color: "var(--title-after)" }} />
                          }
                          label={value}
                        />
                      </RadioGroup>
                    );
                  } else {
                    return (
                      <div>
                        <FormControlLabel
                          control={
                            <CheckBox
                              defaultChecked={allowedValues[key]}
                              value={key}
                              key={key}
                              disabled={disabled}
                            />
                          }
                          label={<div>{value}</div>}
                          style={{ display: "flex", margin: "10px 0" }}
                        />
                      </div>
                    );
                  }
                })()}
              </div>
            ))}
          </FormControl>
        </div>
      )}
      name={field.name}
      defaultValue={field.value.map((el) => el.key).join(",") || ""}
      control={control}
    />
  );
};
