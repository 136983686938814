import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const SecondaryButt = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontWeight: "bold",
    color: "var(--primary-color)",
    border: "1px solid rgba(189, 189, 189, 1)",
    fontSize: 16,
    borderRadius: "100px",
    padding: "10px 18px",
    lineHeight: 1.5,
    backgroundColor: "rgba(255 , 255, 255, 1)",
    "&:hover": {
      backgroundColor: "rgba(245, 245, 245, 1)",
    },
  },
})(Button);

export const SecondaryButton = ({ text, ...props }) => (
  <SecondaryButt variant="contained" color="primary" {...props}>
    {text}
  </SecondaryButt>
);
