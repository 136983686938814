import React from "react";
import { useQuery } from "@apollo/client";
import { VIEW_QUERY } from "../../graphql/Schemas/Queries/Queries";
import { Loader } from "../../components/Loader/Loader";
import { Notification } from "../../components/Notification/Notification";

export const NotificationsPage = ({ messageId, sticky, expired }) => {
  const { loading, data } = useQuery(VIEW_QUERY, {
    variables: {
      viewName: "messages",
      displayId: "rest_export_1",
    },
    fetchPolicy: "cache-and-network",
  });
  if (loading) {
    return <Loader loading={loading} />;
  }
  if (data) {
    return (
      <div>
        <h2 className="breki-title">Értesítések</h2>
        <div className="notifications">
          {data.view &&
            JSON.parse(data.view.content).map((row, idx) => {
              const timestamp = row.field_expiry_date;
              const now = Math.round(Date.now() / 1000)
              if (timestamp >= now) {
                return <Notification key={idx} data={row} date={expired} />;
              }
            })}
        </div>
        <h2 className="breki-title">Előző üzenetek</h2>
        <div className="notifications notifications-expired">
          {data.view &&
            JSON.parse(data.view.content).map((row, idx) => {
              const timestamp = row.field_expiry_date;
              const now = Math.round(Date.now() / 1000);
              if (timestamp < now) {

                return <Notification key={idx} data={row} />;
              }
            })}
        </div>
      </div>
    );
  }

  return null;
};
