import "../src/styles/main.scss";
import { createClient } from "./graphql/Config/client";
import { ApolloProvider } from "@apollo/client";
import AppRouter from "./components/AppRouter/AppRouter";
import { UserContextConsumer, UserContextProvider } from "./utils/UserContext";

export const App = () => {
  const apolloProviderClient = createClient();
  return (
    <ApolloProvider client={apolloProviderClient}>
      <UserContextProvider>
        <UserContextConsumer>
          {() => (
            <div className="App">
              <AppRouter />
            </div>
          )}
        </UserContextConsumer>
      </UserContextProvider>
    </ApolloProvider>
  );
  
};
