import { useEffect } from "react";

export const Logo = () => {
  useEffect(() => {
    const eye1 = document.querySelector(".eye-left");
    const eye2 = document.querySelector(".eye-right");
    window.addEventListener("mousemove", (evt) => {
      const x = -(window.innerWidth / 2 - evt.pageX) / 160;
      const y = -(window.innerHeight / 2 - evt.pageY) / 160;
      if (eye1) {
        eye1.style.transform = `translateY(${y}px) translateX(${x}px)`;
      }
      if (eye2) {
        eye2.style.transform = `translateY(${y}px) translateX(${x}px)`;
      }
    });
  }, []);
  return (
    <svg
      className="nav-logo"
      viewBox="0 0 82 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41 67C58.35 67 74 60.74 80.63 51.22C81.5349 48.5738 81.9977 45.7966 82 43C82 25.33 63.64 11 41 11C18.36 11 0 25.33 0 43C0.00228737 45.7966 0.465149 48.5738 1.37 51.22C8 60.74 23.65 67 41 67Z"
        fill="#58CC02"
      />
      <path
        d="M41 75C60 75 76 64.91 80.63 51.22C74 60.74 58.35 67 41 67C23.65 67 8 60.74 1.37 51.22C6 64.91 22 75 41 75Z"
        fill="#43C000"
      />
      <path
        d="M20 36C29.9411 36 38 27.9411 38 18C38 8.05887 29.9411 0 20 0C10.0589 0 2 8.05887 2 18C2 27.9411 10.0589 36 20 36Z"
        fill="#58CC02"
      />
      <path
        d="M62 36C71.9411 36 80 27.9411 80 18C80 8.05887 71.9411 0 62 0C52.0589 0 44 8.05887 44 18C44 27.9411 52.0589 36 62 36Z"
        fill="#58CC02"
      />
      <path
        d="M62 30C68.6274 30 74 24.6274 74 18C74 11.3726 68.6274 6 62 6C55.3726 6 50 11.3726 50 18C50 24.6274 55.3726 30 62 30Z"
        fill="#F7F7F7"
      />
      <path
        d="M62 22C64.2091 22 66 20.2091 66 18C66 15.7909 64.2091 14 62 14C59.7909 14 58 15.7909 58 18C58 20.2091 59.7909 22 62 22Z"
        fill="#4B4B4B"
        className="eye-right"
      />
      <path
        d="M20 30C26.6274 30 32 24.6274 32 18C32 11.3726 26.6274 6 20 6C13.3726 6 8 11.3726 8 18C8 24.6274 13.3726 30 20 30Z"
        fill="#F7F7F7"
      />
      <path
        d="M20 22C22.2091 22 24 20.2091 24 18C24 15.7909 22.2091 14 20 14C17.7909 14 16 15.7909 16 18C16 20.2091 17.7909 22 20 22Z"
        fill="#4B4B4B"
        className="eye-left"
      />
    </svg>
  );
};
