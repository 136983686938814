import { Field, ConditionalItem } from "../elements";
import { Controller } from "react-hook-form";
import { MenuItem, Select } from "@material-ui/core";
import { useState } from "react";

export const Conditionals = ({
  field,
  register,
  control: control,
  errors,
  setValue,
  disabled,
}) => {
  const [value, currentValue] = useState(field.value[0]?.key);
  let winX = null;
  let winY = null;

  // window.addEventListener("scroll", function () {
  //   if (winX !== null && winY !== null) {
  //     window.scrollTo(winX, winY);
  //   }
  // });
  return (
    <>
      <Field
        field={field}
        value={
          <Controller
            render={({ field: { onChange, value, ref } }, ...props) => (
              <div ref={ref} tabIndex="-1" className="outline-none">
                <Select
                  onChange={(e) => {
                    onChange(e.target.value);
                    currentValue(e.target.value);
                  }}
                  value={value}
                  variant="outlined"
                  displayEmpty
                  disabled={disabled}
                  onOpen={() => {
                    winX = window.scrollX;
                    winY = window.scrollY;
                  }}
                  onClose={() => {
                    winX = null;
                    winY = null;
                  }}
                  className="select"
                  MenuProps={{
                    disableScrollLock: true,
                    className: "select-paper",
                    disablePortal: true,
                  }}
                  style={{ backgroundColor: disabled ? "#FAFAFA" : "white" }}
                >
                  <MenuItem value={undefined} disabled>
                    Válassz egy lehetőséget
                  </MenuItem>
                  {field.allowedValues.map(({ key, value }) => (
                    <MenuItem
                      style={{
                        whiteSpace: "unset",
                        wordBreak: "break-word",
                      }}
                      selected={key == field.value}
                      value={key}
                    >
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}
            name={field.name}
            defaultValue={field.value[0]?.key}
            control={control}
          />
        }
      />
      {(() => {
        let renderFields = [];
        let renderData = [];
        field.conditional.forEach((el) => renderFields.push(...el.children));
        renderFields.forEach((el, idx) =>
          renderData.push(
            <ConditionalItem
              field={[el]}
              register={register}
              control={control}
              errors={errors}
              visible={field?.conditional?.[idx].valuesSet.split(',').includes(`${value}`)}
              setValue={setValue}
              disabled={disabled}
            />
          )
        );
        return renderData;
      })()}
    </>
  );
};
