import * as yup from "yup";

export const schema = {
  forgotPassForm: yup.object().shape({
    pass1: yup
      .string()
      .trim()
      .required("A jelszó megadása kötelező!")
      .min(8, "Minimum 8 karaktert kell tartalmazzon!")
      .matches(/[0-9]/, "Tartalmaznia kell számot.")
      .matches(/[a-z]/, "Szükséges a kis betű!")
      .matches(/[A-Z]/, "Szükséges a nagy betű!"),
    pass2: yup
      .string()
      .trim()
      .required("A jelszó megadása kötelező!")
      .oneOf([yup.ref("pass1"), null], "A jelszavaknak egyezniük kell!"),
  }),
  forgotPass: yup.object().shape({
    mail: yup
      .string()
      .email("Adjon meg egy valós e-mail címet!")
      .required("Az e-mail cím megadás kötelező!"),
  }),
};
