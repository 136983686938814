import { useState } from "react";
import { Link } from "react-router-dom";
import { PrimaryButton, SecondaryButton, Alert } from "../Elements/elements";
import MarkupGenerator from "../MarkupGenerator/MarkupGenerator";
import {
  Card,
  CardContent,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export const Teaser = ({ data }) => {
  const { title, body, tender_info, nid, field_short_summary } = data;
  const tenderInfo = JSON.parse(tender_info);
  const [currentStatus] = useState(parseInt(tenderInfo.status) || 0);
  const [open, setOpen] = useState(false);
  return (
    <Card className="teasers-card">
      <CardContent style={{ padding: "24px" }}>
        <div className="tender-teaser__title">
          {title && (
            <div className="tender-teaser">
              <strong>{title}</strong>
            </div>
          )}

          {tenderInfo.status == null && tenderInfo.button == null ? (
            <div className="tender-teaser__info">{tenderInfo.info}</div>
          ) : (
            tenderInfo.status >= 5 &&
            tenderInfo.status <= 2 && (
              <div className="tender-teaser__info">{tenderInfo.info}</div>
            )
          )}
        </div>
        <div className="tender-teaser">
          <div className="tender-teaser__right">
            {body && (
              <div className="tender-teaser__body">
                <MarkupGenerator
                  onClick={() => setOpen((el) => !el)}
                  style={{
                    cursor: body.length > 500 && "pointer",
                  }}
                  string={
                    body.length > 500
                      ? field_short_summary +
                        "<a><br><br><b>A teljes kiírás megjelenítése<a/>"
                      : body
                  }
                />
                {body.length > 500 && (
                  <Dialog
                    onClose={() => setOpen((el) => !el)}
                    aria-labelledby="dialog-title"
                    open={open}
                  >
                    <DialogTitle
                      disableTypography
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      id="dialog-title"
                    >
                      <div>
                        <b>Pályázati kiírás</b>
                      </div>
                      <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent>
                      <Typography>
                        <MarkupGenerator string={body} />
                      </Typography>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            )}
            {(() => {
              if (tenderInfo.button != "profile") {
                return (
                  <>
                    {tenderInfo?.button != null && (
                      <div className="tender-teaser-buttons">
                        {(tenderInfo?.button == "new" ||
                          tenderInfo?.button == "edit") &&
                          tenderInfo.status < 4 && (
                            <Link
                              to={{
                                pathname:
                                  tenderInfo?.button == "edit"
                                    ? ""
                                    : "/palyazat",
                                state: {
                                  bundle: tenderInfo.bundle,
                                  entityType: tenderInfo.entity_type,
                                  id:
                                    tenderInfo.id == nid ? tenderInfo.id : nid,
                                  new: true,
                                  title,
                                },
                              }}
                              style={{
                                pointerEvents:
                                  tenderInfo?.button == "edit" && "none",
                              }}
                            >
                              <PrimaryButton
                                text="Pályázati űrlap kitöltése"
                                disabled={tenderInfo?.button == "edit"}
                              />
                            </Link>
                          )}

                        {(currentStatus >= 3 || currentStatus == "true") && (
                          <Link
                            to={{
                              pathname: "/palyazat",
                              state: {
                                bundle: tenderInfo.bundle,
                                entityType: tenderInfo.entity_type,
                                id: tenderInfo.id == nid ? nid : tenderInfo.id,
                                status: tenderInfo.status,
                                title,
                                disabled: true,
                              },
                            }}
                          >
                            <SecondaryButton text="Átadott pályázat megtekintése" />
                          </Link>
                        )}
                      </div>
                    )}
                  </>
                );
              } else {
                return (
                  <Link to="/profil">
                    <Alert color="error" text="Pályázás előtt töltsd ki a hiányzó profil adataidat!" />
                  </Link>
                );
              }
            })()}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
