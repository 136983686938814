import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import {
  ENTITY_FIELDS_MUTATION,
  UPLOAD_ENTITY,
} from "../../../graphql/Schemas/Mutations/Mutations";
import { GET_PRINT } from "../../../graphql/Schemas/Queries/Queries";
import { Alert, InputField, MarkupGenerator, PrimaryButton } from "../elements";
import { CircularProgress, IconButton } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";

export const BasicFileUpload = ({ field, currentStatus }) => {
  const [message, setMessage] = useState();
  const [uploadEntity] = useMutation(UPLOAD_ENTITY, { errorPolicy: "all" });
  const [editData] = useMutation(ENTITY_FIELDS_MUTATION, {
    errorPolicy: "all",
  });
  const { data, loading } = useQuery(GET_PRINT, {
    variables: {
      id: parseInt(field.id),
    },
  });
  const onUpload = ({
    target: {
      validity,
      files: [...file],
    },
  }) => {
    if (validity.valid) {
      file.map((file) => {
        uploadEntity({
          variables: {
            file,
            fieldName: field.name,
            entityType: field.entityType,
            bundle: field.bundle,
          },
        }).then(({ data: { uploadFile } }) => {
          editData({
            variables: {
              input: [{ name: field.name, value: `${uploadFile.id}` }],
              entityType: field.entityType,
              bundle: field.bundle,
              id: field.id,
            },
          }).then(
            uploadFile.error
              ? setMessage(uploadFile.error)
              : currentStatus("true")
          );
        });
      });
    }
  };

  return (
    <>
      {(field.bundle !== "tender_sr_language_prep" && field.bundle !== "tender_admission_prep") &&
        (!loading ? (
          <a href={data?.printTender} target="_blank" className="print">
            <PrimaryButton
              startIcon={<PrintIcon style={{ color: "#ffffff" }} />}
              text="Nyomtatás"
            />
          </a>
        ) : (
          <CircularProgress
            size={40}
            style={{ alignSelf: "center", color: "var(--title-after)" }}
          />
        ))}
      {/* <InputField
        type="file"
        id="file"
        name="filename"
        inputProps={{
          accept: ".pdf",
        }}
        onChange={(e) => {
          onUpload(e);
        }}
      />

      <div className="file-upload">
        {message && (
          <Alert
            text={<MarkupGenerator string={message} />}
            color="error"
            close={setMessage}
          />
        )}
      </div> */}
    </>
  );
};
