import React from "react";
import { useQuery } from "@apollo/client";
import { CONFIGURATION } from "../../graphql/Schemas/Queries/Queries";
import { Loader } from "../../components/Elements/elements";

const ConfigInfo = ({ type }) => {
  const { data, loading } = useQuery(CONFIGURATION);

  // Missing error handling & loading.
  //@see https://gitlab.studiopresent.com/drupal8/mnb/-/blob/dev/app/react_frontend/src/components/ConfigInfo/ConfigInfo.js

  if (loading) return <Loader />;

  // I didn't style link inside paragraph

  if (type === "loginText") {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: data.configuration.loginText }}
      ></div>
    );
  }
};

export default ConfigInfo;
