import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const DeleteButt = withStyles({
  root: {
    boxShadow:"none",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: 16,
    borderRadius: "100px",
    padding: "10px 18px",
    backgroundColor: "rgba(235, 87, 87, 1)",
    "&:hover": {
      backgroundColor: "rgba(191, 84, 84, 1)",
    },
    "&:disabled": {
      backgroundColor: "rgba(246, 246, 246, 1)",
    },
  },
})(Button);

export const DeleteButton = ({ text, type ,...props }) => (
  <DeleteButt variant="contained" type={type ? type : "submit"} color="primary" className="primary-button" {...props}>
    {text}
  </DeleteButt>
);
