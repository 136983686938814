export const Fields = [
  {
    type: "text",
    label: "E-mail cím",
    name: "mail",
  },
  {
    type: "password",
    label: "Jelszó",
    name: "password",
  },
];
