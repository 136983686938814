import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const PrimaryButt = withStyles({
  root: {
    boxShadow:"none",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: 16,
    borderRadius: "100px",
    padding: "10px 18px",
    backgroundColor: "var(--primary-color)",
    "&:hover": {
      backgroundColor: "var(--primary-color--hover)",
    },
    "&:disabled": {
      backgroundColor: "rgba(246, 246, 246, 1)",
    },
  },
})(Button);

export const PrimaryButton = ({ text, type ,...props }) => (
  <PrimaryButt variant="contained" type={type ? type : "submit"} color="primary" className="primary-button" {...props}>
    {text}
  </PrimaryButt>
);
