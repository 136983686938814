import { Card, CardContent } from "@material-ui/core";

export const KapcsolatPage = () => {
  return (
    <Card className="register-form-wrapper">
      <CardContent>
        <h2 className="breki-title">Kapcsolat</h2>
        <b>Magyar Nemzeti Tanács</b>
        <br />
        <br />
        Munkaidő: Hétfő–péntek: 8–16 óra
        <br />
        <br />
        Fasizmus áldozatainak tere 9. 24000 Szabadka, Szerbia
        <br />
        <br />
        Tel/Fax: +38124524534
        <br />
        <br />
        Web:
        <br />
        <br />
        <a
          href="https://www.mnt.org.rs"
          target="_blank"
        >
          www.mnt.org.rs
        </a>
        <br />
        <br />
        E-mail:
        <br />
        <br />
        <a
          href="mailto:office@mnt.org.rs"
        >
        office@mnt.org.rs
        </a>
      </CardContent>
    </Card>
  );
};
