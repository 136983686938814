export const Fields = [
  {
    type: "text",
    label: "E-mail cím",
    name: "mail",
  },
];

export const FieldsForm = [
  {
    type: "password",
    label: "Új jelszó",
    name: "pass1",
  },
  {
    type: "password",
    label: "Új jelszó újra",
    name: "pass2",
  },
];
