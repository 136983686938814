import MarkupGenerator from "../../MarkupGenerator/MarkupGenerator";

export const Field = ({ field , wrapper, error , value , hidden = false }) => {
  const { label, type, helpText, prefix, suffix , isRequired } = field;
  return (
    <>
      {wrapper ? (
        <div className={`${wrapper} ${type != undefined ? `${type}` : ""} ${hidden == true ? " hidden" : ""}`}>
          <div className="field">
            {label && <div className="field__label">{label}{isRequired && <span className="required">*</span>}</div>}
            {value && (
              <div className={`field__value${!!prefix ? " prefix" : ''}`}>
                {prefix}
                {value}
                {suffix}
              </div>
            )}
            {error &&<div className="field__error"><MarkupGenerator string={error}/></div>}
            {helpText &&<div className="field__helptext"><MarkupGenerator string={helpText}/></div>}
          </div>
        </div>
      ) : (
        <div className={`field${type != undefined ? ` ${type}` : ""}${hidden == true ? " hidden" : ""}`}>
          {label && <div className="field__label">{label}{isRequired && <span className="required">*</span>}</div>}
          {value && (
            <div className={`field__value${!!prefix ? " prefix" : ''}`}>
              {prefix}
              {value}
              {suffix}
            </div>
          )}
          {error &&<div className="field__error"><MarkupGenerator string={error}/></div>}
          {helpText &&<div className="field__helptext"><MarkupGenerator string={helpText}/></div>}
        </div>
      )}
   </>)
};
