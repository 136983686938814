import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Card,
  CardContent,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { isKelep } from "../../constants";
import EditIcon from "@material-ui/icons/Edit";
import { Alert } from "@material-ui/lab";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Field, Loader, MarkupGenerator, PrimaryButton, SecondaryButton } from "../../components/Elements/elements";
import { ENTITY_FIELDS_MUTATION } from "../../graphql/Schemas/Mutations/Mutations";
import { CURRENT_USER, ENTITY_FIELDS, VIEW_QUERY } from "../../graphql/Schemas/Queries/Queries";
import { UserContext } from "../../utils/UserContext";
import { useFields } from "../../utils/useFields";

export const ProfilPage = () => {
  const { user, setUser } = useContext(UserContext);
  const [edit, setEdit] = useState(false);
  const history = useHistory();
  const [messages, setMessages] = useState("");
  const { ModifyData, Fields } = useFields;
  const [contract, setContract] = useState(false);

  const { data, refetch, loading } = useQuery(ENTITY_FIELDS, {
    variables: { bundle: "user", entityType: "user", id: `${user.id}` },
    fetchPolicy: "no-cache",
  });

  const [User, { data: newUser, loading: userLoading }] = useLazyQuery(CURRENT_USER, {
    fetchPolicy: "no-cache",
  });

  if (newUser) {
    setUser(newUser?.currentUser);
  }

  const [editData] = useMutation(ENTITY_FIELDS_MUTATION);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    setFocus,
  } = useForm({
    mode: "all",
    reValidateMode: "all",
    criteriaMode: "all",
  });

  const onSubmit = async dataForm => {
    dataForm.field_birth_date = dataForm.field_birth_date + "T12:00:00";
    const { input } = ModifyData(dataForm);
    setMessages("");
    editData({
      variables: {
        input: { ...input },
        entityType: "user",
        bundle: "user",
        id: `${user.id}`,
      },
    }).then(({ data: { setEntityFields } }) => {
      if (setEntityFields.errors) {
        setFocus(setEntityFields.errors?.[0].propertyPath);
      }
      setEntityFields.errors?.forEach(({ message, propertyPath }) => {
        if (propertyPath) {
          setError(propertyPath, {
            type: "manual",
            message,
          });
        } else {
          setMessages(el => `${el + message + "<br>"}`);
        }
      });
      User();
      setEntityFields?.success != "FALSE" && setEdit(prev => !prev);
    });
    await refetch();
  };

  const { data: statusData } = useQuery(VIEW_QUERY, {
    variables: {
      viewName: "user_tenders",
      displayId: "rest_export_1",
    },
    fetchPolicy: "network-only",
  });

  const { data: reportData } = useQuery(VIEW_QUERY, {
    variables: {
      viewName: "user_reports",
      displayId: "rest_export_reports",
    },
    fetchPolicy: "network-only",
  });

  // CHECK THIS
  const { data: contractData } = useQuery(VIEW_QUERY, {
    variables: {
      viewName: "szerzodesek",
      displayId: "rest_export_1",
    },
    fetchPolicy: "network-only",
  });

  const { data: appData } = useQuery(VIEW_QUERY, {
    variables: {
      viewName: "nyert_palyazatok",
      displayId: "rest_export_1",
    },
    fetchPolicy: "network-only",
  });

  // if (contractData?.view && appData?.view) {
  //   console.log(JSON.parse(contractData.view?.content));
  //   console.log(JSON.parse(appData.view?.content));
  // }

  if (contractData) {
    let data = JSON.parse(contractData?.view?.content);
    if (data.length) {
      let countContract = true;
      if (data.length != 0 && !contract) {
        setContract(countContract);
      }
    }
  }

  return (
    <>
      <h2 className="breki-title">Profil oldalam</h2>
      {edit ? (
        <Card className="profile-edit-wrapper">
          {messages && (
            <div className="big-alert">
              <Alert text={<MarkupGenerator string={messages} />} color="error" close={setMessages} />
            </div>
          )}
          <div className="edit">
            <IconButton
              onClick={() => {
                setEdit(prev => !prev);
              }}
            >
              <EditIcon />
            </IconButton>
          </div>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              {!loading ? (
                <>
                  <Fields
                    control={control}
                    register={register}
                    errors={errors}
                    data={data?.getEntityFields}
                    setValue={setValue}
                  />
                  <PrimaryButton type="submit" disabled={Object.keys(errors).length} text="Profil frissítése" />
                </>
              ) : null}
            </form>
          </CardContent>
        </Card>
      ) : (
        <div className="profile">
          {!userLoading ? (
            <>
              <div className="card">
                <div style={{ marginBottom: "2rem" }}>
                  <div className="edit" style={{ float: "right" }}>
                    <IconButton
                      onClick={async () => {
                        await refetch();
                        setEdit(prev => !prev);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                  <div className="profile-name">
                    {user.lastName} {user.firstName}
                  </div>
                  <div className="card-title-sub">{user.role}</div>
                </div>
                <div className="profile__header">
                  <div className="profile__image">
                    <img src={process.env.REACT_APP_DRUPAL_URL + user.picture} alt="profil" />
                  </div>
                  <div className="profile__infos">
                    <div className="profile__email">
                      <Field
                        field={{
                          label: <span style={{ fontSize: "14px" }}>E-mail</span>,
                        }}
                        value={user.mail}
                      />
                    </div>
                    <div className="profile__name">
                      <Field
                        field={{
                          label: <span style={{ fontSize: "14px" }}>Vezetéknév</span>,
                        }}
                        value={
                          user.lastName || (
                            <div className="field__helptext" style={{ margin: 0 }}>
                              Nem adtad meg a vezetékneved
                            </div>
                          )
                        }
                      />
                    </div>
                    <div className="profile__firstname">
                      <Field
                        field={{
                          label: <span style={{ fontSize: "14px" }}>Keresztnév</span>,
                        }}
                        value={
                          user.firstName || (
                            <div className="field__helptext" style={{ margin: 0 }}>
                              Nem adtad meg a keresztneved
                            </div>
                          )
                        }
                      />
                    </div>
                    <div className="profile__fathersname">
                      <Field
                        field={{
                          label: <span style={{ fontSize: "14px" }}>Édesapja neve</span>,
                        }}
                        value={
                          user.fathersName || (
                            <div className="field__helptext" style={{ margin: 0 }}>
                              Nem adtad meg az édesapád nevét
                            </div>
                          )
                        }
                      />
                    </div>
                    <div className="profile__dateofbirth">
                      <Field
                        field={{
                          label: <span style={{ fontSize: "14px" }}>Születési dátum</span>,
                        }}
                        value={
                          user.birthDate || (
                            <div className="field__helptext" style={{ margin: 0 }}>
                              Nem adtad meg a születési dátumod
                            </div>
                          )
                        }
                      />
                    </div>
                    <div className="profile__dateofbirth">
                      <Field
                        field={{
                          label: <span style={{ fontSize: "14px" }}>Befejezett középiskola</span>,
                        }}
                        value={
                          user.completedHighSchool || (
                            <div className="field__helptext" style={{ margin: 0 }}>
                              Nem adtad meg a középiskoládat
                            </div>
                          )
                        }
                      />
                    </div>
                    <div className="profile__dateofbirth">
                      <Field
                        field={{
                          label: <span style={{ fontSize: "14px" }}>Teljes neved szerbül</span>,
                        }}
                        value={
                          user.fullNameSr || (
                            <div className="field__helptext" style={{ margin: 0 }}>
                              Nem adtad meg a teljes neved szerbül
                            </div>
                          )
                        }
                      />
                    </div>

                    <div className="profile__dateofbirth">
                      <Field
                        field={{
                          label: <span style={{ fontSize: "14px" }}>Születési hely</span>,
                        }}
                        value={
                          user.placeOfBirth || (
                            <div className="field__helptext" style={{ margin: 0 }}>
                              Nem adtad meg a születési helyed
                            </div>
                          )
                        }
                      />
                    </div>

                    <div className="profile__dateofbirth">
                      <Field
                        field={{
                          label: <span style={{ fontSize: "14px" }}>Szerep</span>,
                        }}
                        value={
                          user.role || (
                            <div className="field__helptext" style={{ margin: 0 }}>
                              Nincs szereped
                            </div>
                          )
                        }
                      />
                    </div>

                    <div className="profile__dateofbirth">
                      <Field
                        field={{
                          label: <span style={{ fontSize: "14px" }}>Lakhely</span>,
                        }}
                        value={
                          user.residence || (
                            <div className="field__helptext" style={{ margin: 0 }}>
                              Nem adtad meg a lakhelyed
                            </div>
                          )
                        }
                      />
                    </div>

                    <div className="profile__dateofbirth">
                      <Field
                        field={{
                          label: <span style={{ fontSize: "14px" }}>Utca, házszám</span>,
                        }}
                        value={
                          user.streetAddress || (
                            <div className="field__helptext" style={{ margin: 0 }}>
                              Nem adtad meg az utcád és a házszámod
                            </div>
                          )
                        }
                      />
                    </div>

                    <div className="profile__city">
                      <Field
                        field={{
                          label: <span style={{ fontSize: "14px" }}>Utca, házszám szerbül</span>,
                        }}
                        value={
                          user.streetAddressSr || (
                            <div className="field__helptext" style={{ margin: 0 }}>
                              Nem adtad meg az utcád és a házszámod szerbül
                            </div>
                          )
                        }
                      />
                    </div>

                    <div className="profile__city">
                      <Field
                        field={{
                          label: <span style={{ fontSize: "14px" }}>Irányítószám</span>,
                        }}
                        value={
                          user.zipCode || (
                            <div className="field__helptext" style={{ margin: 0 }}>
                              Nem adtad meg az irányítószámod
                            </div>
                          )
                        }
                      />
                    </div>
                    <div className="profile__phone">
                      <Field
                        field={{
                          label: <span style={{ fontSize: "14px" }}>Mobil</span>,
                        }}
                        value={
                          user.phone || (
                            <p className="field__helptext" style={{ margin: 0 }}>
                              Még nem adtad meg a telefonszámod
                            </p>
                          )
                        }
                      />
                    </div>
                    <div className="profile__firstname">
                      <Field
                        field={{
                          label: <span style={{ fontSize: "14px" }}>JMBG</span>,
                        }}
                        value={
                          user.jmbg || (
                            <div className="field__helptext" style={{ margin: 0 }}>
                              Nem adtad meg a JMBG számod
                            </div>
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              {statusData ? (
                <div>
                  <h3 className="breki-title">Pályázataim</h3>
                  <div className="teasers">
                    {statusData?.view ? (
                      <TableContainer component={Paper} className="tender-status">
                        <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <b>Pályázati kiírás</b>
                              </TableCell>
                              <TableCell align="right">
                                <b>Státusz</b>
                              </TableCell>
                              <TableCell align="right"></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {statusData?.view &&
                              JSON.parse(statusData.view?.content).map((row, idx) => {
                                const tenderInfo = JSON.parse(row.tender_info);
                                return (
                                  <TableRow>
                                    <TableCell component="th" id={idx} scope="row">
                                      {row.title}
                                    </TableCell>
                                    <TableCell align="right">{tenderInfo.info}</TableCell>
                                    <TableCell align="right">
                                      <SecondaryButton
                                        onClick={() => {
                                          history.push({
                                            pathname: "/palyazat",
                                            state: {
                                              bundle: tenderInfo.bundle,
                                              entityType: tenderInfo.entity_type,
                                              id: tenderInfo.id,
                                              title: row?.title,
                                              disabled: true,
                                            },
                                          });
                                        }}
                                        text="Megtekintés"
                                        style={{ padding: "8px" }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : null}
                  </div>
                </div>
              ) : (
                <Loader loading={loading} />
              )}
              {contract && !isKelep && (
                <div>
                  <h3 className="breki-title">Szerződéseim</h3>
                  <div className="teasers">
                    <TableContainer component={Paper} className="tender-status">
                      <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <b>Pályázat</b>
                            </TableCell>
                            <TableCell align="right">
                              <b>Beadás dátuma</b>
                            </TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {contractData?.view &&
                            JSON.parse(contractData.view?.content).map((row, idx) => {
                              const options = {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              };

                              const formattedDate = new Date(row.created[0]?.value).toLocaleDateString(
                                "hu-HU",
                                options,
                              );
                              const titleCont = row.title[0]?.value;
                              const dateCont = row.created[0]?.value;
                              const pathCont = row.field_contract_document[0]?.url;
                              const valueCont = row.field_contract_state[0]?.value;

                              if (valueCont === "open" || valueCont === "draft" || valueCont === "needs_update")
                                return (
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      {titleCont}
                                    </TableCell>
                                    <TableCell align="right">{formattedDate}</TableCell>
                                    <TableCell align="right">
                                      <SecondaryButton
                                        onClick={() => {
                                          history.push({
                                            pathname: "/szerkesztes",
                                            state: {
                                              title: titleCont,
                                              disabled: true,
                                              id: row.nid[0].value,
                                            },
                                          });
                                        }}
                                        text="Megnyitás"
                                        style={{ padding: "8px" }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              else if (valueCont === "locked" || valueCont === "signing" || valueCont === "signed")
                                return (
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      {titleCont}
                                    </TableCell>
                                    <TableCell align="right">{dateCont}</TableCell>
                                    <TableCell align="right">
                                      <SecondaryButton
                                        href={pathCont}
                                        target="_blank"
                                        text="Megtekintés"
                                        style={{ padding: "8px" }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              else if (valueCont === undefined) return null;
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              )}
              {reportData?.view && !isKelep && !isKelep && JSON.parse(reportData.view?.content).length ? (
                <div>
                  {reportData?.view && <h3 className="breki-title">Jelentéseim</h3>}
                  <TableContainer component={Paper} className="tender-status">
                    <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Pályázat</b>
                          </TableCell>
                          <TableCell align="right">
                            <b>Beadás dátuma</b>
                          </TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reportData?.view &&
                          JSON.parse(reportData.view?.content).map((row, idx) => {
                            const tenderInfo = JSON.parse(row.tender_info);
                            return (
                              <TableRow>
                                <TableCell component="th" id={idx} scope="row">
                                  {row.title}
                                </TableCell>
                                <TableCell align="right">{row.created}</TableCell>
                                <TableCell align="right">
                                  <SecondaryButton
                                    onClick={() =>
                                      history.push({
                                        pathname: "/jelentes",
                                        state: {
                                          existingid: row.nid,
                                          bundle: tenderInfo.bundle,
                                        },
                                      })
                                    }
                                    text="Megtekintés"
                                    style={{ padding: "8px" }}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      )}
    </>
  );
};
