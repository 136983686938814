import {
  Accordion as A,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { PrimaryButton } from "../elements";
import { AccordionItem } from "./AccordionItem";
import MarkupGenerator from "../../MarkupGenerator/MarkupGenerator";

export const Accordion = ({
  field,
  register,
  control,
  unregister,
  disabled,
  errors
}) => {
  const defaultQuantity = [];
  field.referenceValues?.forEach((el) => defaultQuantity.push(el.id));
  const [quantity, setQuantity] = useState(defaultQuantity);

  const getDisabled = () => {
    if (field.cardinality == -1 && !disabled) {
      return false;
    } else {
      return disabled;
    }
  }
  useEffect(() => {
    if(quantity.length > 0){
      unregister(field.name)
    }
  }, [quantity])
  return (
    <div className={`accordion-wrapper ${errors?.[field.name]?.message ? 'accordion-wrapper--error' : ''}`}>
      <A
        style={{
          borderRadius: "8px",
        }}
        className="accordion"
        defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{ borderRadius: "8px" }}
        >
          <b>{field.label}</b>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column", rowGap: "10px" }}>
          {quantity.length > 0 ? (
            quantity.map((el, idx) => {
              return (
                <AccordionItem
                  id={el}
                  register={register}
                  control={control}
                  unregister={unregister}
                  field={field}
                  disabled={disabled}
                  parent={field.name + `-${quantity[idx]}`}
                  errors={errors}
                  onClick={() => {
                    setQuantity(quantity.filter((item, idy) => idy != idx));
                  }}
                />
              );
            })
          ) : (
            <div style={{ color: "rgba(0,0,0,0.5)" }}>
              <input {...register(field.name)} hidden></input>
              Nincs hozzárendelt tétel
            </div>
          )}
        </AccordionDetails>
        {field.widgetType !== "report_entity_reference_paragraphs" &&
          <AccordionActions
            style={{
              borderRadius: "8px",
              marginBottom: "1rem",
            }}
          >
            <PrimaryButton
              onClick={() => {
                const id =
                  typeof quantity[quantity.length - 1] != "number" &&
                  quantity.length
                    ? parseInt(
                        quantity[quantity.length - 1]
                          .split("")
                          .reverse()
                          .join("")[0]
                      ) + 1
                    : 1;
                !getDisabled() &&
                  setQuantity((quantity) => [...quantity, `new${id}`]);
              }}
              type="button"
              text="Új tétel hozzáadása"
              disabled={getDisabled()}
            />
          </AccordionActions>
        }
      </A>
      {errors?.[field.name]?.message && (<div className="field__error"><MarkupGenerator string={errors?.[field.name]?.message}/></div>)}
    </div>
  );
};
