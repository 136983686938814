import React from "react";

const MarkupGenerator = ({ string, className, onClick, style }, ...props) => (
  <div
    dangerouslySetInnerHTML={{ __html: string }}
    className={className}
    onClick={() => (onClick ? onClick() : null)}
    style={style}
    {...props}
  />
);
export default MarkupGenerator;
