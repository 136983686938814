import Autocomplete from "@material-ui/lab/Autocomplete";
import { Controller } from "react-hook-form";

export const AutoComplete = ({
  options = [],
  renderInput,
  getOptionLabel,
  onChange: ignored,
  control,
  defaultValue,
  name,
  renderOption,
  multiple,
  disabled
}) => {
  return (
    <Controller
      render={({ field: { onChange }, ...props }) => (
        <Autocomplete
          autoComplete
          multiple={multiple}
          options={options}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderInput={renderInput}
          defaultValue={defaultValue}
          disabled={disabled}
          onChange={(e, data) => onChange({ data })}
          getOptionSelected={(option, value) => option.key === value.key}
          className={disabled ? "autocomplete--disabled" : ""}
          {...props}
        />
      )}
      onChange={([, data]) => data}
      name={name}
      defaultValue={{ data: defaultValue }}
      control={control}
    />
  );
};
