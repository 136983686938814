import { useEffect, useRef, useState } from "react";
import { Grow, Popper, Paper, ClickAwayListener, MenuList } from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import { LOGOUT } from "../../graphql/Schemas/Queries/Queries";
import { useLazyQuery } from "@apollo/client";
import { Logo } from "./Logo";
import { LogoKelep } from "./LogoKelep";
import { isKelep } from "../../constants";
import NotificationsCount from "../Notification/NotificationCount";

export const NavBar = ({ user }) => {
  const [Logout] = useLazyQuery(LOGOUT, {
    onCompleted: () => window.location.reload(),
  });
  const [open, setOpen] = useState(false);
  const prevOpen = useRef(open);
  const anchorRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const handleClick = event => {
    setIsActive(current => !current);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      window.onscroll = function () {
        setOpen(false);
      };
    } else {
      window.onscroll = function () {};
    }
  }, [open]);

  return (
    <nav className="nav">
      <Link to="/" className="nav-link nav-left">
        {isKelep ? (
          <>
            <LogoKelep />
            <div className="nav-logo-wrapper">
              <span className="nav-logo-title">Kelep</span>
              <span>Központi Elektronikus Pályázatok</span>
            </div>
          </>
        ) : (
          <>
            <Logo />
            <span className="nav-logo-title">Breki</span>
          </>
        )}
      </Link>
      <div className="nav-link nav-right">
        {!isKelep ? (
          <div className="notifications">
            {user ? (
              <>
                <NavLink to="/ertesitesek" activeClassName="is-active" className="nav-notification" />
                <NotificationsCount />
              </>
            ) : null}
          </div>
        ) : null}

        <div
          ref={anchorRef}
          className={`nav-menu${open ? " active" : ""}`}
          onClick={() => setOpen(prevOpen => !prevOpen)}
        />

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          modifiers={{
            preventOverflow: {
              enabled: false,
            },
          }}
          placement={"top-end"}
          disablePortal={true}
          style={{ borderTopWidth: "0", outline: "none", zIndex: 10, overflow: "inherit" }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom-end" ? "right top" : "right top",
              }}
            >
              <Paper className="paper">
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} className={`paper-list${user ? " nav-user" : ""}`}>
                    {user ? (
                      <>
                        <div className="nav-popper">
                          <NavLink onClick={() => setOpen(false)} to="" className="nav-link" exact>
                            <div className="nav-item">
                              <img src={"svgs/home.svg"} />
                              Kezdőoldal
                            </div>
                          </NavLink>
                          <NavLink onClick={() => setOpen(false)} to="/palyazatok" className="nav-link">
                            <div className="nav-item">
                              <img src={"svgs/palyazatok.svg"} />
                              Pályázatok
                            </div>
                          </NavLink>
                          <NavLink onClick={() => setOpen(false)} to="/profil" className="nav-link">
                            <div className="nav-item">
                              <img src={"svgs/prof.svg"} />
                              Profil
                            </div>
                          </NavLink>
                          <NavLink onClick={() => setOpen(false)} to="/kapcsolat" className="nav-link">
                            <div className="nav-item">
                              <img src={"svgs/info.svg"} />
                              Kapcsolat
                            </div>
                          </NavLink>
                          <NavLink onClick={() => setOpen(false)} to="/dokumentumok" className="nav-link">
                            <div className="nav-item">
                              <img src={"svgs/doc.svg"} />
                              Dokumentumok
                            </div>
                          </NavLink>
                          <a className="nav-link">
                            <div onClick={Logout} className="nav-item">
                              <img src={"svgs/logout.svg"} />
                              Kijelentkezés
                            </div>
                          </a>
                        </div>
                        <div className="user">
                          <div className="user-picture">
                            {user.picture ? (
                              <img src={process.env.REACT_APP_DRUPAL_URL + user.thumbnail} />
                            ) : (
                              <img src="svgs/defaultuser.svg" />
                            )}
                          </div>
                          <div className="user-name">
                            <div>
                              {user.lastName} {user.firstName}
                            </div>
                            <span>{user.role}</span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="nav-popper nouser">
                        <NavLink onClick={() => setOpen(false)} to="/bejelentkezes" className="nav-link">
                          <div className="nav-item">Bejelentkezés</div>
                        </NavLink>
                        <NavLink onClick={() => setOpen(false)} to="/regisztracio" className="nav-link">
                          <div className="nav-item">Regisztráció</div>
                        </NavLink>
                        <NavLink onClick={() => setOpen(false)} to="/kapcsolat" className="nav-link">
                          <div className="nav-item">Kapcsolat</div>
                        </NavLink>
                      </div>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </nav>
  );
};
