import { useEffect } from "react";
import { useFields } from "../../../utils/useFields";

export const ConditionalItem = ({
  register,
  control,
  setValue,
  parent,
  field,
  visible,
  errors,
  disabled
}) => {
  const { Fields } = useFields;

  useEffect(() => {
    if (!visible) {
        setValue(field[0].name,'');
    }
  }, [visible]);

  if (visible) {
    return (
      <div>
        <Fields
          data={field}
          register={register}
          control={control}
          parent={parent}
          errors={errors}
          disabled={disabled}
        />
      </div>
    );
  }
  return null;
};
