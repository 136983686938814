import React from "react";
import { useQuery } from "@apollo/client";
import { VIEW_QUERY } from "../../graphql/Schemas/Queries/Queries";
import { Loader } from "../../components/Loader/Loader";
import { Notification } from "../../components/Notification/Notification";

function NotificationsSticky() {
  const { loading, data } = useQuery(VIEW_QUERY, {
    variables: {
      viewName: "messages",
      displayId: "rest_export_1",
    },
    fetchPolicy: "cache-and-network"
  });
  // console.log(JSON.parse(data?.view?.content));
  if (loading) {
    return <Loader loading={loading}/>;
  }
  if (data) {
    return (
      <div>
        <div className="notifications">
          {data.view &&
            JSON.parse(data.view.content).map((row, idx) => {
              const timestamp = row.field_expiry_date;
              const now = Math.round(Date.now() / 1000);
              let sticky = row.field_sticky;
              if (sticky == '1' && (timestamp >= now)) {
              return <Notification key={idx} data={row}  />;}

            })}
        </div>
      </div>
    );
  }

  return null;
}

export default NotificationsSticky
