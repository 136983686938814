import React, { createContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { CURRENT_USER } from "../graphql/Schemas/Queries/Queries";
import { Loader } from "../../src/components/Loader/Loader";

const UserContext = createContext();

const UserContextProvider = (props) => {
  const { loading, data } = useQuery(CURRENT_USER);
  const [user, setUser] = useState();

  useEffect(() => {
    if (data?.currentUser?.id !== 0) {
      setUser(data?.currentUser);
    } else {
      setUser(false);
    }
  }, [loading]);

  if (!loading && user !== undefined) {
    return (
      <UserContext.Provider value={{ user, setUser }}>
        {props.children}
      </UserContext.Provider>
    );
  } else {
    return <Loader loading={loading}/>;
  }
};
const UserContextConsumer = UserContext.Consumer;

export { UserContextProvider, UserContext, UserContextConsumer };
