import { useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import {
  PrimaryButton,
  Loader,
  MarkupGenerator,
} from "../../components/Elements/elements";
import { Alert } from "@material-ui/lab";
import { ENTITY_FIELDS } from "../../graphql/Schemas/Queries/Queries";
import { useFields } from "../../utils/useFields";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ENTITY_FIELDS_MUTATION } from "../../graphql/Schemas/Mutations/Mutations";
import { useState } from "react";
import { Card, CardContent } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

export const HaviJelentesPage = () => {
  const { ModifyData, Fields, getYupSchema } = useFields;
  const [messages, setMessages] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { state } = useLocation();

  const { loading: formLoading, data } = useQuery(ENTITY_FIELDS, {
    variables: {
      bundle:
        state?.bundle === "tender_demonstrator"
          ? "demonstrators_report"
          : "report",
      entityType: "node",
      id: state?.existingid ? state?.existingid : "",
    },
    fetchPolicy: "no-cache",
  });
  
  if (!state) {
    history.push("/");
  }

  const [editData] = useMutation(ENTITY_FIELDS_MUTATION);
  
  const {
    register,
    handleSubmit,
    unregister,
    control,
    formState: { errors },
    setError,
    setValue,
    setFocus,
  } = useForm({
    mode: "all",
    reValidateMode: "all",
    criteriaMode: "all",
    shouldUnregister: true,
    resolver: yupResolver(
      yup.object().shape(getYupSchema(data?.getEntityFields)),
      { abortEarly: false }
    ),
  });

  const onSubmit = (dataForm) => {
    setLoading(true)

    const { input } = ModifyData(dataForm);
    setMessages("");
    const newReport = [{ name: "field_tender_ref", value: `${state?.id}` }];
    editData({
      variables: {
        input: input.concat(newReport),
        entityType: "node",
        bundle:
          state?.bundle === "tender_demonstrator"
            ? "demonstrators_report"
            : "report",
      },
    }).then(({ data: { setEntityFields } }) => {
      if (setEntityFields?.errors) {
        setFocus(setEntityFields.errors?.[0].propertyPath);
      }
      setEntityFields.errors?.forEach(({ message, propertyPath, parentId }) => {
        const pathSuffix = parentId ? `-${parentId}` : "";
        if (propertyPath) {
          setError(propertyPath + pathSuffix, {
            type: "manual",
            message,
          });
        } else {
          setMessages((el) => `${el + message + "<br>"}`);
        }
      });
      if (setEntityFields?.success !== "FALSE") {
        history.push({
          pathname: "/",
          state: { message: setEntityFields.success },
        });

        return;
      }
      
      setLoading(false);
    });
  };
  return (
    <div className="tender">
      <h2 className="breki-title">
        {state?.bundle === "tender_demonstrator"
          ? `Demonstrátori jelentés ${state?.existingid ? "megtekintése" : "beküldése"}`
          : `Havi jelentés ${state?.existingid ? "megtekintése" : "beküldése"}`}
      </h2>
      <div>
        {formLoading ? (
          <Loader loading={formLoading} />
        ) : (
          <Card className="tender-wrapper">
            {messages && (
              <div className="big-alert">
                <Alert
                  text={<MarkupGenerator string={messages} />}
                  color="error"
                  close={setMessages}
                />
              </div>
            )}
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Fields
                  control={control}
                  register={register}
                  errors={errors}
                  unregister={unregister}
                  data={data?.getEntityFields}
                  setValue={setValue}
                  disabled={!!state?.existingid}
                />
                {data && !state?.existingid && (
                  <PrimaryButton
                    type="submit"
                    // disabled={Object.keys(errors).length }
                    disabled={loading}
                    text="Jelentés beküldése"
                  />
                )}
              </form>
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
};
