import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { Card, CardContent } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  Field,
  InputField,
  PrimaryButton,
} from "../../components/Elements/elements";
import { schema } from "../../components/YupSchema/schema";
import {
  USER_RESET_PASSWORD,
  USER_CONFIRM_PASSWORD,
} from "../../graphql/Schemas/Mutations/Mutations";
import { FieldsForm } from "./FormFields";

export const ForgotPasswordForm = () => {
  const [message, setMessage] = useState();
  const history = useHistory();
  const [userResetPassMutation] = useMutation(USER_RESET_PASSWORD, { errorPolicy: "all" });
  const { userId, timestamp, hash } = useParams();

  const [userConfirmPassMutation, { data }] = useMutation(
    USER_CONFIRM_PASSWORD, { errorPolicy: "all" }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "all",
    criteriaMode: "all",
    resolver: yupResolver(schema.forgotPassForm, { abortEarly: false }),
  });

  const onSubmit = ({ pass1, pass2 }) => {
    userResetPassMutation({
      variables: {
        userId: parseInt(data.userConfirmPass.id),
        password: pass1,
        passwordAgain: pass2,
      },
    }).then(({ data }) => {
      setMessage(data.userResetPass.success);
    });
  };

  useEffect(() => {
    userConfirmPassMutation({
      variables: {
        userId: parseInt(userId),
        timestamp: parseInt(timestamp),
        hash: hash,
      },
    }).then(({  errors }) => {
      if (errors !== undefined) {
        setMessage(errors[0].message);
      }
    });
  }, []);

  if (message != undefined) {
    history.push({
      pathname: "/bejelentkezes",
      state: { message: message , type : data.userConfirmPass?.id != null ? "success" : "error" },
    });
  }
  if (data?.userConfirmPass) {
    return (
      <Card className="forgot-form-wrapper">
        <CardContent>
          <h2 className="breki-title">Elfelejtett Jelszó</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="forgot-form">
              {FieldsForm.map(({ type, label, name }, idx) => {
                return (
                  <Field
                    field={{ label }}
                    error={errors?.[name]?.message}
                    value={
                      <InputField
                        key={idx}
                        inputProps={{ ...register(`${name}`) }}
                        type={type}
                        placeholder={label}
                        error={!!errors[name]}
                      />
                    }
                  />
                );
              })}
              <div className="forgot-form-button">
                <PrimaryButton
                  disabled={Object.keys(errors).length}
                  text="Mentés"
                />
              </div>
            </div>
          </form>
          <div></div>
        </CardContent>
      </Card>
    );
  }
  return null;
};
