import { useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import {
  PrimaryButton,
  Loader,
  MarkupGenerator,
  SecondaryButton,
} from "../../components/Elements/elements";
import { Alert } from "@material-ui/lab";
import { ENTITY_FIELDS } from "../../graphql/Schemas/Queries/Queries";
import { useFields } from "../../utils/useFields";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ENTITY_FIELDS_MUTATION } from "../../graphql/Schemas/Mutations/Mutations";
import { useState } from "react";
import { Card, CardContent } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

export const FormForContract = () => {
  const { ModifyData, Fields, getYupSchema } = useFields;
  const [messages, setMessages] = useState("");
  const { state } = useLocation();
  const { loading, data } = useQuery(ENTITY_FIELDS, {
    variables: {
      bundle: "contract",
      entityType: "node",
      id: state?.id ? String(state?.id) : "",
    },
    fetchPolicy: "no-cache",
  });
  const history = useHistory();
  if (!state) {
    history.push("/");
  }
  const [editData] = useMutation(ENTITY_FIELDS_MUTATION);
  const {
    register,
    handleSubmit,
    unregister,
    control,
    formState: { errors },
    setError,
    setValue,
    setFocus,
  } = useForm({
    mode: "all",
    reValidateMode: "all",
    criteriaMode: "all",
    resolver: yupResolver(
      yup.object().shape(getYupSchema(data?.getEntityFields)),
      { abortEarly: false }
    ),
  });

  const onSubmit = (dataForm, event) => {
    const { input } = ModifyData(dataForm);
    const lockedValue = [{ name: "field_contract_state", value: "locked" }];

    const updatedValues = input.concat(lockedValue);

    setMessages("");
    const newContract = state.new
      ? [{ name: "field_tender_ref", value: `${state.id}` }]
      : {};

    const returnedValues =
      event.target.innerText == "Mentés" ? input : updatedValues;

    editData({
      variables: {
        input: { ...returnedValues, ...newContract },
        bundle: state?.bundle,
        entityType: state?.entityType,
        id: !state.new ? String(state?.id) : "",
      },
    }).then(({ data: { setEntityFields } }) => {
      let focus = setEntityFields?.errors?.find(
        ({ propertyPath }) => propertyPath != null
      );
      if (focus != undefined) {
        setFocus(focus.propertyPath);
      } else {
        window.scrollTo(0, 550);
      }
      setEntityFields.errors?.forEach(({ message, propertyPath }) => {
        if (propertyPath) {
          setError(propertyPath, {
            type: "manual",
            message,
          });
        } else {
          setMessages((el) => `${el + message + "<br>"}`);
        }
      });
      if (setEntityFields?.success != "FALSE") {
        history.push({
          pathname: "/",
          state: { message: setEntityFields.success },
        });
      }
    });
  };

  return (
    <div className="contract">
      <h2 className="breki-title">Szerződéskötés: Adataim</h2>
      <div>
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <Card className="contract-wrapper">
            {messages && (
              <div className="big-alert">
                <Alert
                  text={<MarkupGenerator string={messages} />}
                  color="error"
                  close={setMessages}
                />
              </div>
            )}
            <CardContent>
              <form>
                <Fields
                  control={control}
                  register={register}
                  errors={errors}
                  unregister={unregister}
                  data={data?.getEntityFields}
                  setValue={setValue}
                  disabled={state?.disabled}
                />
                <div className="form-buttons">
                  <SecondaryButton
                    onClick={() => history.push("/")}
                    text="Vissza"
                  />
                  <PrimaryButton
                    disabled={Object.keys(errors).length}
                    type="submit"
                    text="Mentés"
                    name="submit"
                    id="submit"
                    onClick={handleSubmit(onSubmit)}
                  />

                  <PrimaryButton
                    // onClick={() => history.push("/")}
                    text="Véglegesítés"
                    type="submit"
                    name="save"
                    id="save"
                    onClick={handleSubmit(onSubmit)}
                  />
                </div>
              </form>
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
};
