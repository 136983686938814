import { PageHelmet } from "../PageHelmet/PageHelmet";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import {
  PalyazatokPage,
  ForgotPasswordPage,
  HaviJelentesPage,
  HomePage,
  LoginPage,
  Page404,
  PalyazatJelentesPage,
  ProfilPage,
  RegistrationPage,
  KapcsolatPage,
  ForgotPasswordForm,
  Dokumentumok,
  FormForContract,
  NotificationsPage
} from "../../pages/pages";
import { Footer } from "../Footer/Footer";
import { NavBar } from "../NavBar/NavBar";
import { PrivateRoute } from "./PrivateRoute";
import React, { useContext, useEffect } from "react";
import { UserContext } from "../../utils/UserContext";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import PrivacyPolicy from "../../pages/PrivacyPolicy/PrivacyPolicy";

function AppRouter() {
  const { user } = useContext(UserContext);

  const ScrollToTop = () => {
    const history = useHistory();

    useEffect(() => {
      const unlisten = history.listen(() => {
        window.scrollTo(0, 0);
      });
      return () => {
        unlisten();
      };
    }, [history]);

    return null;
  };
  return (
    <Router>
      <PageHelmet />
      <NavBar user={user} />
      <div className="content">
        <ScrollToTop />
        <Switch>
          {user && [
            <Redirect exact push from="/bejelentkezes" to="/" />,
            <Redirect exact push from="/regisztracio" to="/" />,
          ]}
          <PrivateRoute path={`/`} exact component={HomePage} />
          <PrivateRoute
            path={`/szerkesztes`}
            exact
            component={FormForContract}
          />
          <PrivateRoute path={`/jelentes`} exact component={HaviJelentesPage} />
          <PrivateRoute path={`/profil`} exact component={ProfilPage} />
          <PrivateRoute
            path={`/palyazat`}
            exact
            component={PalyazatJelentesPage}
          />
          <PrivateRoute path={`/dokumentumok`} exact component={Dokumentumok} />
          <PrivateRoute path={`/palyazatok`} exact component={PalyazatokPage} />
          <Route path={`/kapcsolat`} exact component={KapcsolatPage} />
          <PrivateRoute path={`/ertesitesek`} exact component={NotificationsPage} />
          {!user && (
            <GoogleReCaptchaProvider reCaptchaKey="6Ld6J0kqAAAAALStEaqjgq87w7FE1UIJJPkJYfU5">
              <Route path={`/bejelentkezes`} exact component={LoginPage} />
              <Route
                path={`/regisztracio`}
                exact
                component={RegistrationPage}
              />
              <Route
                path={`/elfelejtett-jelszo`}
                exact
                component={ForgotPasswordPage}
              />
              <Route
                path={`/elfelejtett-jelszo/:userId/:timestamp/:hash`}
                exact
                component={ForgotPasswordForm}
              />
              <Route
                path={`/adatkezelesi-tajekoztato`}
                exact
                component={PrivacyPolicy}
              />
              <Route path={`/kapcsolat`} exact component={KapcsolatPage} />
            </GoogleReCaptchaProvider>
          )}
          <Route component={Page404} />
          <Route
            path={`/adatkezelesi-tajekoztato`}
            exact
            component={PrivacyPolicy}
          />
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default AppRouter;
