import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import { isKelep, siteTitle } from "../../constants";

export const PageHelmet = () => {
  const location = useLocation();
  return (
    <Helmet>
      <title>
        {(() => {
          switch (location.pathname) {
            case "/profil":
              return "Profil | ";
            case "/palyazatok":
              return "Pályázatok | ";
            case "/jelentes":
              return "Jelentés | ";
            case "/bejelentkezes":
              return "Bejelentkezés | ";
            case "/regisztracio":
              return "Regisztráció | ";
            case "/palyazat":
              return "Pályázat | ";
            case "/kapcsolat":
              return "Kapcsolat | ";
            case "/dokumentumok":
              return "Dokumentumok | ";
            default:
              return "Főoldal | ";
          }
        })()}
        {siteTitle}
      </title>
      <meta name="description" content={siteTitle} />

      {isKelep && [
        <link rel="apple-touch-icon" sizes="180x180" href="fav-kelep/apple-touch-icon.png" />,
        <link rel="icon" type="image/png" sizes="32x32" href="fav-kelep/favicon-32x32.png" />,
        <link rel="icon" type="image/png" sizes="16x16" href="fav-kelep/favicon-16x16.png" />,
        <link rel="manifest" href="fav-kelep/site.webmanifest" />,
        <link rel="mask-icon" href="fav-kelep/safari-pinned-tab.svg" color="#5bbad5" />,
      ]}
    </Helmet>
  );
};
