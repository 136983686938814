import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import CookieConsent from "react-cookie-consent";
import { currentTheme, siteTitle } from "./constants";

let vh = window.innerHeight * 0.01;

document.documentElement.style.setProperty("--vh", `${vh}px`);

document.documentElement.setAttribute("data-theme", currentTheme)

ReactDOM.render(
  <React.StrictMode>
    <CookieConsent
      location="bottom"
      buttonText="Elfogadom"
      cookieName="gdpr"
      style={{
        background: "var(--notification-color)",
        display: "flex",
        alignItems: "center",
        justifyContent: "unset",
      }}
      buttonStyle={{
        color: "var(--primary-color)",
        fontSize: "13px",
        backgroundColor: "rgba(255 , 255, 255, 1)",
        fontSize: 16,
        borderRadius: "100px",
        border: "1px solid rgba(189, 189, 189, 1)",
      }}
      contentStyle={{ flex: "unset" }}
      expires={150}
    >
      A {siteTitle} weboldal HTTP-sütiket (cookie) használ. <br />
      <span style={{ fontSize: "10px" }}>
        A sütik optimálissá teszik a felhasználói élményt. További tudnivalókat
        az{" "}
        <a
          href="http://www.mnt.org.rs/sites/default/files/attachments/adatkezelesi_tajekoztato_2019_mnt.pdf"
          style={{ color: "white" }}
          target="_blank"
        >
          Adatkezelési nyilatkozatban
        </a>{" "}
        találsz.
      </span>
    </CookieConsent>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
