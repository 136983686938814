import { Field, ConditionalItem, Alert } from "../elements";
import { Controller } from "react-hook-form";
import { MenuItem, Select } from "@material-ui/core";
import { useState } from "react";

export const CshsConditional = ({
  field,
  register,
  control: control,
  errors,
  setValue,
  data,
  disabled,
}) => {
  let winX = null;
  let winY = null;

  const [value, currentValue] = useState(field?.referenceValues?.[0]?.id);

  const defaultValue = field.referenceValues?.[0].parents
    ? [...field.referenceValues[0]?.parents, 0]
    : [0];
  const [values, setValues] = useState(defaultValue.reverse());
  const [message, setMessage] = useState();

  // window.addEventListener("scroll", function () {
  //   if (winX !== null && winY !== null) {
  //     window.scrollTo(winX, winY);
  //   }
  // });
  return (
    <>
      <Field
        field={field}
        error={errors?.[field.name]?.message}
        value={
          <>
            <div className="hierarchical">
              {(() => {
                let selects = [];
                for (let index = 0; index < values.length; index++) {
                  let renderData = data;
                  const level = values[index];
                  renderData = renderData[level];
                  renderData?.sort((a, b) => a.locationKey - b.locationKey);
                  if (renderData) {
                    selects.push(
                      <>
                        <Controller
                          render={(
                            { field: { onChange, ref }, fieldState: { error } },
                            ...props
                          ) => (
                            <div tabIndex="-1" ref={ref} className="outline-none select">
                              <Select
                                variant="outlined"
                                displayEmpty
                                key={index}
                                error={!!error}
                                disabled={disabled}
                                className="select"
                                MenuProps={{
                                  disableScrollLock: true,
                                  disablePortal: true,
                                  className: "select-paper",
                                }}
                                style={{
                                  backgroundColor: disabled
                                    ? "#FAFAFA"
                                    : "white",
                                }}
                                defaultValue={values[index + 1] || field.value}
                                onOpen={() => {
                                  winX = window.scrollX;
                                  winY = window.scrollY;
                                }}
                                onClose={() => {
                                  winX = null;
                                  winY = null;
                                }}
                                onChange={(e) => {
                                  onChange(e.target.value);
                                  currentValue(e.target.value);
                                  setValues((values) => [
                                    ...values.slice(0, index + 1),
                                    `${e.target.value}`,
                                  ]);
                                  if (data[e.target.value]) {
                                    setMessage("Ne hagyd üresen!");
                                  } else {
                                    setMessage();
                                  }
                                }}
                                {...props}
                              >
                                {(() => {
                                  let options = [];
                                  options.push(
                                    <MenuItem
                                      value={values[index + 1] || field.value}
                                      disabled
                                    >
                                      Válassz egy lehetőséget
                                    </MenuItem>
                                  );
                                  renderData.forEach(
                                    ({ value, locationKey }) => {
                                      options.push(
                                        <MenuItem value={locationKey}>
                                          {value}
                                        </MenuItem>
                                      );
                                    }
                                  );
                                  return options;
                                })()}
                              </Select>
                            </div>
                          )}
                          name={field.name}
                          defaultValue={values[values.length - 1] || ""}
                          control={control}
                        />
                      </>
                    );
                  }
                }
                return selects;
              })()}
            </div>
            {message && (
              <Alert text={message} color="error" close={setMessage} />
            )}
          </>
        }
      />
      {(() => {
        let renderFields = [];
        let renderData = [];
        field.conditional.forEach((el) => renderFields.push(...el.children));
        renderFields.forEach((el, idx) =>
          renderData.push(
            <ConditionalItem
              field={[el]}
              register={register}
              control={control}
              errors={errors}
              visible={field?.conditional?.[idx].valuesSet.split(',').includes(`${value}`)}
              setValue={setValue}
              disabled={disabled}
            />
          )
        );
        return renderData;
      })()}
    </>
  );
};
