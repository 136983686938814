import { React, useState }  from "react";
import { useQuery } from "@apollo/client";
import { VIEW_QUERY } from "../../graphql/Schemas/Queries/Queries";
import { Loader } from "../../components/Loader/Loader";
import { Notification } from "../../components/Notification/Notification";

function NotificationsCount() {
  const { loading, data } = useQuery(VIEW_QUERY, {
    variables: {
      viewName: "messages",
      displayId: "rest_export_1",
    },
    fetchPolicy: "cache-and-network"
  });

  const msg = () => {
    let count = 0;
    JSON.parse(data.view.content).map((row, idx,) => {
      const timestamp = row.field_expiry_date;
      const now = Math.round(Date.now() / 1000);
      if (timestamp > now) {
        count++
      }
    })

    return count;

  }
  if (loading) {
    return <Loader loading={loading}/>;
  }
  if (data) {
    const active_msg = msg();
    return (
        <div className="notifications-count">
          <div className="notifications-count__number">{active_msg}</div>
        </div>
    );
  }

  return  null;
}

export default NotificationsCount
