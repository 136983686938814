import { TextField, withStyles } from "@material-ui/core";

const Input = withStyles({
  root: {
    "& label": {
      color: "rgba(139, 139, 139, 1)",
    },
    "& input": {
      color: "rgba(75, 75, 75, 1)",
    },
    "& fieldset": {
      borderRadius: "8px",
    },
    "& input.Mui-disabled": {
      backgroundColor: "#FAFAFA",
      color: "rgba(0, 0, 0, 0.38)",
    },
    "& .Mui-disabled": {
      backgroundColor: "#FAFAFA",
      color: "rgba(0, 0, 0, 0.38)",
    },
    "& label.Mui-focused": {
      fontWeight: "bold",
      color: "rgba(139, 139, 139, 1)",
    },
    "& label.Mui-error": {
      color: "#f44336",
    },
    "& input:valid:focus + fieldset": {
      borderColor: "rgba(229, 229, 229, 1)",
    },
    "& input:valid:hover + fieldset": {
      borderColor: "rgba(229, 229, 229, 1)",
    },
    "& .MuiInputBase-root.Mui-error": {
      "& input:valid:focus + fieldset": {
        borderColor: "#f44336",
      },
      "& input:valid:hover + fieldset": {
        borderColor: "#f44336",
      },
    },
  },
})(TextField);

export const InputField = ({ error, autocomplete, label, ...props }) =>
  autocomplete ? (
    <TextField
      style={{ backgroundColor: "white" }}
      error={error}
      variant="outlined"
      color="primary"
      {...props}
    />
  ) : (
    <Input error={error} variant="outlined" color="primary" {...props} />
  );
