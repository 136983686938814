import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { Card, CardContent } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  Alert,
  Field,
  InputField,
  PrimaryButton,
} from "../../components/Elements/elements";
import { schema } from "../../components/YupSchema/schema";
import { FORGOT_PASSWORD } from "../../graphql/Schemas/Mutations/Mutations";
import { Fields } from "./FormFields";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";

export const ForgotPasswordPage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [message, setMessage] = useState();
  const [token, setToken] = useState();
  const [ForgotPassword] = useMutation(FORGOT_PASSWORD, { errorPolicy: "all" });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "all",
    criteriaMode: "all",
    resolver: yupResolver(schema.forgotPass, { abortEarly: false }),
  });
  const onSubmit = ({ mail }) => {
    if (token)
      ForgotPassword({
        variables: {
          mail,
        },
      }).then(({ data, errors }) => {
        setMessage({
          message: errors ? errors[0].message : data.userForgotPass.success,
          type: errors ? "error" : "success",
        });
      });
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (executeRecaptcha) {
      const token = await executeRecaptcha("yourAction");
      setToken(token);
    }
  }, []);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <Card className="forgot-form-wrapper">
      <CardContent>
        <h2 className="breki-title">Elfelejtett Jelszó</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="forgot-form">
            {Fields.map(({ type, label, name }, idx) => {
              return (
                <Field
                  field={{ label }}
                  error={errors?.[name]?.message}
                  value={
                    <InputField
                      key={idx}
                      inputProps={{ ...register(`${name}`) }}
                      type={type}
                      placeholder={label}
                      error={!!errors[name]}
                    />
                  }
                />
              );
            })}
            <div className="forgot-form-button">
              <PrimaryButton
                disabled={Object.keys(errors).length}
                text="Küldés"
              />
            </div>
          </div>
          <div className="form-links">
            <div>
              Már regisztrált? <Link to="/bejelentkezes">Jelentkezzen be</Link>
            </div>
            <div>
              <Link to="/regisztracio">Regisztráció</Link>
            </div>
          </div>
          <GoogleReCaptcha onVerify={setToken} />
        </form>
        <div>
          {message && (
            <Alert
              text={message.message}
              color={message.type}
              close={setMessage}
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
};
