import { useQuery } from "@apollo/client";
import { VIEW_QUERY } from "../../graphql/Schemas/Queries/Queries";
import { MarkupGenerator } from "../../components/Elements/elements";
import { Card, CardContent } from "@material-ui/core";

export const Dokumentumok = () => {
  const { data } = useQuery(VIEW_QUERY, {
    variables: {
      viewName: "dokumentumok",
      displayId: "rest_export_1",
    },
    fetchPolicy: "network-only",
  });

  return (
    <Card className="register-form-wrapper">
      <CardContent>
        <h2 className="breki-title">Dokumentumok</h2>
        {data &&
          JSON.parse(data?.view?.content).map((el) => (
            <div>
              <h4 style={{ marginBottom: "10px" }}>
                {el.field_palyazati_kiiras}
              </h4>
              <div>{el.field_dokumentumok_leirasa}</div>
              <div className="dokumentumok">
                <MarkupGenerator string={el.field_dokumentumok} />
              </div>
            </div>
          ))}
      </CardContent>
    </Card>
  );
};
