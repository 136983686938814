import React from "react";
import { useQuery } from "@apollo/client";
import { VIEW_QUERY } from "../../graphql/Schemas/Queries/Queries";
import { Teaser } from "../../components/Teaser/Teaser";
import { Loader } from "../../components/Loader/Loader";

export const PalyazatokPage = () => {
  const { loading, data } = useQuery(VIEW_QUERY, {
    variables: {
      viewName: "tenders",
      displayId: "rest_export_tenders",
    },
    fetchPolicy: "cache-and-network"
  });
  if (loading) {
    return <Loader loading={loading}/>;
  }
  if (data) {
    return (
      <div>
        <h2 className="breki-title">Pályázatok</h2>
        <div className="teasers">
          {data.view &&
            JSON.parse(data.view.content).map((row, idx) => {
              return <Teaser key={idx} data={row} />;
            })}
        </div>
      </div>
    );
  }

  return null;
};
