import { siteTitle } from "../../constants";

export const Footer = () => (
  <footer className="footer">
    <div className="footer-images" style={{ alignItems: "center" }}>
      <a href="https://www.facebook.com/magyarnemzetitanacs" target="_blank" rel="noreferrer">
        <img src="/svgs/facebook.svg" alt="#" />
      </a>
      <a href="https://www.instagram.com/magyar_nemzeti_tanacs_mnt" target="_blank" rel="noreferrer">
        <img src="/svgs/insta.svg" alt="#" />
      </a>
    </div>
    <div className="footer-text">
      © <b>{ new Date().getFullYear() } {siteTitle} - Magyar Nemzeti Tanács.</b> Minden jog fenntartva.
    </div>
  </footer>
);
