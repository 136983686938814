import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  Loader,
  PrimaryButton,
  MarkupGenerator,
} from "../../components/Elements/elements";
import { Card, CardContent } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useFields } from "../../utils/useFields";
import { ENTITY_FIELDS } from "../../graphql/Schemas/Queries/Queries";
import { ENTITY_FIELDS_MUTATION } from "../../graphql/Schemas/Mutations/Mutations";
import { useState } from "react";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";

export const RegistrationPage = () => {
  const [message, setMessage] = useState();
  const [messages, setMessages] = useState("");
  const { ModifyData, Fields, getYupSchema } = useFields;
  const { loading, data } = useQuery(ENTITY_FIELDS, {
    variables: { bundle: "user", entityType: "user" },
    fetchPolicy: "no-cache",
  });
  const [token, setToken] = useState();
  const [Registration] = useMutation(ENTITY_FIELDS_MUTATION);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    setFocus,
  } = useForm({
    mode: "all",
    reValidateMode: "all",
    criteriaMode: "all",
    resolver: yupResolver(
      yup.object().shape(getYupSchema(data?.getEntityFields)),
      { abortEarly: false }
    ),
  });

  const onSubmit = (dataForm) => {
    const { input } = ModifyData(dataForm);
    setMessages("");
    if (token)
      Registration({
        variables: {
          input: { ...input },
          entityType: "user",
          bundle: "user",
        },
      }).then(({ data: { setEntityFields } }) => {
        if (setEntityFields.errors) {
          setFocus(setEntityFields.errors?.[0].propertyPath);
        }
        setEntityFields.errors?.forEach(({ message, propertyPath }) => {
          if (propertyPath) {
            setError(propertyPath, {
              type: "manual",
              message,
            });
          } else {
            setMessages((el) => `${el + message + "<br>"}`);
          }
        });
        if (setEntityFields.success != "FALSE") {
          window.location.reload();
        }
      });
  };
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Ld6J0kqAAAAALStEaqjgq87w7FE1UIJJPkJYfU5">
      <Card className="register-form-wrapper">
        <CardContent>
          <h2 className="breki-title">Regisztráció</h2>
          {messages && (
            <div className="big-alert">
              <Alert
                text={<MarkupGenerator string={messages} />}
                color="error"
                close={setMessages}
              />
            </div>
          )}
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <form className="node" onSubmit={handleSubmit(onSubmit)}>
              <Fields
                control={control}
                register={register}
                errors={errors}
                data={data?.getEntityFields}
                setValue={setValue}
              />
              {data && (
                <div className="login-form-button">
                  <PrimaryButton
                    type="submit"
                    disabled={Object.keys(errors).length}
                    text="Regisztráció"
                  />
                </div>
              )}
              <GoogleReCaptcha onVerify={setToken} />
            </form>
          )}
          <div className="form-links">
            <div>
              Már regisztrált? <Link to="/bejelentkezes">Jelentkezzen be</Link>
            </div>
            <div>
              <Link to="/elfelejtett-jelszo">Elfelejtett jelszó?</Link>
            </div>
          </div>
          {message && (
            <Alert
              text={message.message}
              color={message.type}
              close={setMessage}
            />
          )}
        </CardContent>
      </Card>
    </GoogleReCaptchaProvider>
  );
};
