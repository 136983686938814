import { Helmet } from "react-helmet";
import { siteTitle } from "../../constants";

export const Page404 = () => (
  <div>
    <Helmet>
      <title>404 | {siteTitle}</title>
    </Helmet>
    <h2 className="breki-title" >Az oldal nem található</h2>
  </div>
);
