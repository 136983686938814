import { gql } from "@apollo/client";

export const VIEW_QUERY = gql`
  # REAL QUERY!
  query quizList(
    $viewName: String!
    $displayId: String!
    $viewArgs: [String]
    $queryArgs: String
  ) {
    view(
      viewName: $viewName
      displayId: $displayId
      viewArgs: $viewArgs
      queryArgs: $queryArgs
    ) {
      title
      content
    }
  }
`;

export const CURRENT_USER = gql`
  # REAL QUERY!
  query currentUserQuery {
    currentUser {
      id
      mail
      username
      firstName
      lastName
      fathersName
      fullNameSr
      birthDate
      jmbg
      residence
      zipCode
      streetAddress
      streetAddressSr
      placeOfBirth
      phone
      picture
      thumbnail
      role
      biography
      hunElementarySchool
      hunHighSchool
      completedHighSchool
    }
  }
`;

export const CONFIGURATION = gql`
  # REAL QUERY!
  query configurationQuery {
    configuration {
      loginText
    }
  }
`;

export const IS_LOGGED_IN = gql`
  #REAL QUERY!
  query isLoggedInQuery {
    isLoggedIn
  }
`;

export const LOGOUT = gql`
  #REAL QUERY!
  query userLogout {
    userLogout {
      success
    }
  }
`;

export const GET_PRINT = gql`
  query MyQuery($id: Int!) {
    printTender(id: $id)
  }
`;

export const ENTITY_FIELDS = gql`
  query MyQuery($bundle: String, $entityType: String!, $id: String) {
    getEntityFields(
      entityType: $entityType
      bundle: $bundle
      formMode: "default"
      id: $id
    ) {
      conditional {
        condition
        effect
        state
        valuesSet
        valuesSetMode
        grouping
        children {
          allowedExtensions
          allowedValues {
            key
            parentKey
            value
          }
          cardinality
          defaultValue {
            key
            parentKey
            value
          }
          formatSettings {
            classes
            description
            formatter
            id
          }
          helpText
          isRequired
          fakeRequired
          label
          maxLength
          maxUploadSize
          maximum
          minimum
          name
          offLabel
          onLabel
          placeholder
          prefix
          referenceValues {
            fileUri
            id
            imgAlt
            parents
          }
          suffix
          targetBundle
          targetType
          type
          value {
            key
            parentKey
            value
          }
          weight
          widgetType
        }
      }
      allowedExtensions
      allowedValues {
        key
        parentKey
        value
      }
      widgetType
      weight
      value {
        key
        parentKey
        value
      }
      targetType
      targetBundle
      suffix
      referenceValues {
        fileUri
        id
        imgAlt
        parents
      }
      prefix
      placeholder
      onLabel
      offLabel
      name
      type
      minimum
      maximum
      maxUploadSize
      maxLength
      label
      isRequired
      fakeRequired
      helpText
      children {
        conditional {
          condition
          effect
          state
          valuesSet
          valuesSetMode
          grouping
          children {
            allowedExtensions
            allowedValues {
              key
              parentKey
              value
            }
            cardinality
            defaultValue {
              key
              parentKey
              value
            }
            formatSettings {
              classes
              description
              formatter
              id
            }
            helpText
            isRequired
            fakeRequired
            label
            maxLength
            maxUploadSize
            maximum
            minimum
            name
            offLabel
            onLabel
            placeholder
            prefix
            referenceValues {
              fileUri
              id
              imgAlt
              parents
            }
            suffix
            targetBundle
            targetType
            type
            value {
              key
              parentKey
              value
            }
            weight
            widgetType
          }
        }
        allowedExtensions
        cardinality
        helpText
        isRequired
        fakeRequired
        label
        maxLength
        maxUploadSize
        maximum
        minimum
        name
        type
        offLabel
        onLabel
        placeholder
        prefix
        suffix
        targetBundle
        targetType
        weight
        widgetType
        value {
          key
          parentKey
          value
        }
        referenceValues {
          fileUri
          id
          imgAlt
          parents
        }
        children {
          conditional {
            condition
            effect
            state
            valuesSet
            valuesSetMode
            grouping
            children {
              allowedExtensions
              allowedValues {
                key
                parentKey
                value
              }
              cardinality
              defaultValue {
                key
                parentKey
                value
              }
              formatSettings {
                classes
                description
                formatter
                id
              }
              helpText
              isRequired
              fakeRequired
              label
              maxLength
              maxUploadSize
              maximum
              minimum
              name
              offLabel
              onLabel
              placeholder
              prefix
              referenceValues {
                fileUri
                id
                imgAlt
                parents
              }
              suffix
              targetBundle
              targetType
              type
              value {
                key
                parentKey
                value
              }
              weight
              widgetType
            }
          }
          allowedExtensions
          cardinality
          allowedValues {
            key
            parentKey
            value
          }
          defaultValue {
            key
            parentKey
            value
          }
          formatSettings {
            classes
            description
            formatter
            id
          }
          helpText
          isRequired
          fakeRequired
          label
          maxLength
          maxUploadSize
          maximum
          minimum
          name
          type
          offLabel
          onLabel
          placeholder
          prefix
          referenceValues {
            fileUri
            id
            imgAlt
            parents
          }
          suffix
          targetBundle
          targetType
          value {
            key
            parentKey
            value
          }
          weight
          widgetType
          children {
            conditional {
              condition
              effect
              state
              valuesSet
              valuesSetMode
              grouping
              children {
                allowedExtensions
                allowedValues {
                  key
                  parentKey
                  value
                }
                cardinality
                defaultValue {
                  key
                  parentKey
                  value
                }
                formatSettings {
                  classes
                  description
                  formatter
                  id
                }
                helpText
                isRequired
                fakeRequired
                label
                maxLength
                maxUploadSize
                maximum
                minimum
                name
                offLabel
                onLabel
                placeholder
                prefix
                referenceValues {
                  fileUri
                  id
                  imgAlt
                  parents
                }
                suffix
                targetBundle
                targetType
                type
                value {
                  key
                  parentKey
                  value
                }
                weight
                widgetType
              }
            }
            allowedExtensions
            cardinality
            allowedValues {
              key
              parentKey
              value
            }
            helpText
            isRequired
            fakeRequired
            label
            maxLength
            maxUploadSize
            maximum
            minimum
            type
            name
            offLabel
            onLabel
            placeholder
            prefix
            referenceValues {
              fileUri
              id
              imgAlt
              parents
            }
            suffix
            targetBundle
            targetType
            value {
              key
              parentKey
              value
            }
            weight
            widgetType
            defaultValue {
              key
              parentKey
              value
            }
            formatSettings {
              classes
              description
              formatter
              id
            }
          }
        }
        defaultValue {
          key
          parentKey
          value
        }
        formatSettings {
          classes
          description
          formatter
          id
        }
        allowedValues {
          key
          parentKey
          value
        }
      }
      cardinality
      defaultValue {
        key
        parentKey
        value
      }
      formatSettings {
        classes
        description
        formatter
        id
      }
    }
  }
`;
