import { PalyazatokPage } from "./PalyazatokPage/PalyazatokPage";
import { HaviJelentesPage } from "./HaviJelentesPage/HaviJelentesPage";
import { HomePage } from "./HomePage/HomePage";
import { LoginPage } from "./LoginPage/LoginPage";
import { PalyazatJelentesPage } from "./PalyazatJelentesPage/PalyazatJelentesPage";
import { ProfilPage } from "./ProfilPage/ProfilPage";
import { RegistrationPage } from "./RegistrationPage/RegistrationPage";
import { Page404 } from "./404/Page404";
import { ForgotPasswordPage } from "./ForgotPasswordPage/ForgotPasswordPage";
import { KapcsolatPage } from "./KapcsolatPage/KapcsolatPage";
import { ForgotPasswordForm } from "./ForgotPasswordPage/ForgotPasswordForm";
import { Dokumentumok } from "./Dokumentumok/Dokumentumok";
import { FormForContract } from "./FormForContract/FormForContract";
import { NotificationsPage } from "./Notifications/NotificationsPage";

export {
  PalyazatokPage,
  Dokumentumok,
  HaviJelentesPage,
  HomePage,
  LoginPage,
  PalyazatJelentesPage,
  ProfilPage,
  RegistrationPage,
  Page404,
  ForgotPasswordPage,
  KapcsolatPage,
  ForgotPasswordForm,
  FormForContract,
  NotificationsPage
};
