import { MenuItem, Select } from "@material-ui/core";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { Alert } from "../elements";

export const Hierarchical = ({ data, control, field, disabled }) => {
  let winX = null;
  let winY = null;
  const defaultValue = field.referenceValues?.[0].parents
    ? [...field.referenceValues[0]?.parents, 0]
    : [0];
  const [values, setValues] = useState(defaultValue.reverse());
  const [message, setMessage] = useState();
  // @todo add error to the form


  // window.addEventListener("scroll", function () {
  //   if (winX !== null && winY !== null) {
  //     window.scrollTo(winX, winY);
  //   }
  // });
  return (
    <>
      <div className="hierarchical">
        {(() => {
          let selects = [];
          for (let index = 0; index < values.length; index++) {
            let renderData = data;
            const level = values[index];
            renderData = renderData[level];
            renderData?.sort((a, b) => a.locationKey - b.locationKey);
            if (renderData) {
              selects.push(
                <Controller
                  render={({ field: { ref, onChange } , fieldState : {error} }, ...props) => (
                    <div ref={ref} tabIndex="-1" className="outline-none select">
                      <Select
                        variant="outlined"
                        displayEmpty
                        id={index}
                        key={index}
                        error={!!error}
                        disabled={disabled}
                        className="select"
                        MenuProps={{
                          disableScrollLock: true,
                          disablePortal: true,
                          className: "select-paper select",
                        }}
                        style={{
                          backgroundColor: disabled ? "#FAFAFA" : "white",
                        }}
                        onOpen={() => {
                          winX = window.scrollX;
                          winY = window.scrollY;
                        }}
                        onClose={() => {
                          winX = null;
                          winY = null;
                        }}
                        defaultValue={values[index + 1] || field.value}
                        onChange={(e) => {
                          onChange(e.target.value)
                          setValues((values) => [
                            ...values.slice(0, index + 1),
                            `${e.target.value}`,
                          ]);
                          if (data[e.target.value]) {
                            setMessage("Ne hagyd üresen!");
                          } else {
                            setMessage();
                          }
                        }}
                        {...props}
                      >
                        {(() => {
                          let options = [];
                          options.push(
                            <MenuItem
                              value={values[index + 1] || field.value}
                              disabled
                            >
                              Válassz egy lehetőséget
                            </MenuItem>
                          );
                          renderData.forEach(({ value, locationKey }) => {
                            options.push(
                              <MenuItem value={locationKey}>{value}</MenuItem>
                            );
                          });
                          return options;
                        })()}
                      </Select>
                      </div>
                  )}
                  name={field.name}
                  defaultValue={values[values.length - 1] || ""}
                  control={control}
                />
              );
            }
          }
          return selects;
        })()}
      </div>
      {message && <Alert text={message} color="error" close={setMessage} />}
    </>
  );
};
