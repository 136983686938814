import React from "react";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <div>
      <Helmet>
        <title>Adatkezelési tájékoztató</title>
      </Helmet>
      <h2 className="breki-title">Adatkezelési tájékoztató</h2>
      <h3 className="breki-title">About us</h3>
      <p className="privacy-policy-paragraph">
        This application is the property of Studio Present LLC, a web design
        agency, seated at the Somborski put 33a, 24 000 Subotica, Serbia. Studio
        Present LLC is a privately held company registered in the Republic of
        Serbia:
      </p>
      <ul className="privacy-policy-list">
        <li>Company ID number: 20581140</li>
        <li>TIN: 106339862</li>
        <li>E-mail: info@studiopresent.com</li>
      </ul>

      <h3 className="breki-title">General Information</h3>
      <p className="privacy-policy-paragraph">
        Studio Present is very committed to data privacy and we welcome the
        latest European General Data Protection Regulation (GDPR), which came
        into force on 25 May 2018. This new legislation gives you greater
        security, transparency, and control of your personal data online. We
        treat your personal data as confidential and in accordance with the
        statutory data protection regulations and this privacy policy. All data
        of a personal nature that identifies you directly or indirectly will be
        handled fairly, lawfully, and with due care. This privacy policy
        explains what kind of information we collect and what we use it for. It
        also explains how and for what purpose this happens. Please note that
        data transmitted via the internet (e.g. via email communication) may be
        subject to security breaches. Complete protection of your data from
        third-party access is not possible.
      </p>

      <h3 className="breki-title">How do we collect your data?</h3>

      <p className="privacy-policy-paragraph">
        Some data is collected when you provide it to us, for example, the data
        you enter in our contact form or while signing up to use the
        application. Other data is collected automatically by our IT systems
        when you visit the website (statistics).
      </p>
      <h3 className="breki-title">
        What rights do you have regarding your data?
      </h3>
      <p className="privacy-policy-paragraph">
        You have the right to access, rectify, erase, and receive your personal
        data, as well as restrict its processing or object to the same, as
        provided in Articles 17 to 24 of Regulation (EU) 2018/1725. You can
        contact us at any time using our contact form if you have such a request
        or further questions about the issue of privacy and data protection. If
        you would like to receive the data we processed based on your consent or
        in fulfillment of a contract, this will be done in a standard,
        machine-readable format to the extent technically feasible.
      </p>
      <h3 className="breki-title">How long can data be kept?</h3>
      <p className="privacy-policy-paragraph">
        Personal data will be kept only for the time needed to achieve the
        purpose to which it is processed. All data relating to the project will
        be stored for the duration of the project. In the event of a formal
        appeal, all data held at the time of the appeal will be retained until
        the completion of the appeal process.
      </p>

      <h3 className="breki-title">Third-party data processing</h3>
      <p className="privacy-policy-paragraph">
        When visiting our website, statistical analyses may be made of your
        surfing behavior. This happens primarily using cookies and analytics.
        The analysis of using the application is usually anonymous, i.e. we will
        not be able to identify you from this data. You can object to this
        analysis or prevent it by not using certain tools. Further details can
        be found in the section Analytics and Advertising below.
      </p>
      <p className="privacy-policy-paragraph">
        We collaborate with these third-party data processors: Google (Privacy
        policy)
      </p>

      <h3 className="breki-title">
        Revocation of your consent to the processing of your data
      </h3>
      <p className="privacy-policy-paragraph">
        Many data processing operations are only possible with your expressed
        consent. You may revoke your consent at any time with future effect. An
        informal email making this request is sufficient. The data processed
        before we receive your request may still be legally processed.
      </p>

      <h3 className="breki-title">Right to data portability</h3>
      <p className="privacy-policy-paragraph">
        You have the right to have data that we process based on your consent or
        in fulfillment of a contract automatically delivered to yourself or to a
        third party in a standard, machine-readable format. If you require the
        direct transfer of data to another responsible party, this will only be
        done to the extent technically feasible.
      </p>

      <h3 className="breki-title">Information, blocking, deletion</h3>
      <p className="privacy-policy-paragraph">
        As permitted by law, you have the right to be provided at any time with
        information free of charge about any of your personal data that is
        stored as well as its origin, the recipient and the purpose for which it
        has been processed. You also have the right to have this data corrected
        blocked or deleted. You can contact us at any time using the address
        given in our legal notice if you have further questions on this topic of
        personal data.
      </p>

      <h3 className="breki-title">Opposition to promotional emails</h3>
      <p className="privacy-policy-paragraph">
        We hereby expressly prohibit the use of contact data published in the
        context of application legal notice requirements with regard to sending
        promotional and informational materials not expressly requested. The
        application operator reserves the right to take specific legal action if
        unsolicited advertising material, such as email spam, is received.
      </p>

      <h3 className="breki-title">Amendments to this privacy policy</h3>
      <ul className="privacy-policy-list">
        <li>Studio Present can amend this Privacy Policy as needed.</li>
        <li>
          Studio Present keeps the right to change this Privacy Policy when
          necessary, in order to align and act in accordance with relevant
          legislation and regulations.
        </li>
        <li>
          If we implement some changes, we will not explicitly inform our
          clients or website users. Instead, we recommend you visit this page
          occasionally to stay informed about the changes to this Policy.
        </li>
      </ul>

      <h3 className="breki-title">What are cookies?</h3>
      <p className="privacy-policy-paragraph">
        Cookies do not harm your computer and do not contain any viruses.
        Cookies help make our website more user-friendly, efficient, and secure.
        Cookies are small text files that are stored on your computer and saved
        by your browser.
      </p>
      <p className="privacy-policy-paragraph">
        Most of the cookies we use are so-called 'session cookies. They are
        automatically deleted after your visit. Other cookies remain in your
        device's memory until you delete them. These cookies make it possible to
        recognize your browser when you next visit the site.
      </p>
      <p className="privacy-policy-paragraph">
        You can configure your browser to inform you about the use of cookies so
        that you can decide on a case-by-case basis whether to accept or reject
        a cookie. Alternatively, your browser can be configured to automatically
        accept cookies under certain conditions or always reject them or to
        automatically delete cookies when closing your browser. Disabling
        cookies may limit the functionality of this website.
      </p>

      <h3 className="breki-title">How we use cookies</h3>
      {/* <p className="privacy-policy-paragraph"></p> */}
      <ul className="privacy-policy-list">
        <li>We use website analytics and performance cookies.</li>
        <li>
          We only use cookies necessary for tracking users’ visits to our
          website and internal pages. This refers to analytical
          cookies/performance cookies, and the whole process is done
          anonymously, so we cannot discover the identity of our website user,
          by any means.
        </li>
        <li>
          We use cookies to target an audience. These cookies are connected with
          Facebook Pixel and used for more precise targeting of an audience
          during Facebook ad placement.
        </li>
      </ul>

      <h3 className="breki-title">Contact form</h3>
      <p className="privacy-policy-paragraph">
        Should you send us questions via the contact form, we will collect the
        data entered in the form, including the contact details you provide, to
        answer your question and any follow-up questions. We do not share this
        information without your permission.
      </p>
      <p className="privacy-policy-paragraph">
        We will retain the data you provide in the contact form until you
        request its deletion, revoke your consent for its storage, or the
        purpose for its storage no longer pertains (e.g. after fulfilling your
        request). Any mandatory statutory provisions, especially those regarding
        mandatory data retention periods, remain unaffected by this provision.
      </p>

      <h3 className="breki-title">Google reCAPTCHA</h3>
      <p className="privacy-policy-paragraph">
        In order to ensure sufficient data security during the transmission of
        forms, we use the service reCAPTCHA provided by Google. This is mainly
        used to distinguish whether the input is made by a natural person or
        created by a machine and processed automatically. In the frame of this
        service, Google will receive the IP address and possibly other data
        required. For further information on the privacy policy of Google Inc.,
        please refer to www.google.com/intl/de/policies/privacy/.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
