import { CircularProgress, Fade } from "@material-ui/core";
export const Loader = ({ loading, style = true }) => (
  <Fade
    in={loading}
    style={{
      transitionDelay: loading ? "500ms" : "0ms",
    }}
    unmountOnExit
  >
    <div
      style={
        style
          ? {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "100vh",
            }
          : ""
      }
    >
      <CircularProgress
        disableShrink
        style={{ color: "var(--title-after)" }}
      />
    </div>
  </Fade>
);
