import { useMutation } from "@apollo/client";
import { useState } from "react";
import { Controller } from "react-hook-form";
import {
  DELETE_ENTITY,
  UPLOAD_ENTITY,
} from "../../../graphql/Schemas/Mutations/Mutations";
import MarkupGenerator from "../../MarkupGenerator/MarkupGenerator";
import { Alert, InputField } from "../elements";

export const FileUpload = ({ field, control, error, setValue, disabled }) => {
  const [files, setFiles] = useState(field?.referenceValues || []);
  const [message, setMessage] = useState();
  const [deleteEntity] = useMutation(DELETE_ENTITY, { errorPolicy: "all" });
  const [uploadEntity] = useMutation(UPLOAD_ENTITY, { errorPolicy: "all" });
  const onUpload = (
    {
      target: {
        validity,
        files: [...file],
      },
    },
    onChange
  ) => {
    if (validity.valid) {
      file.map((file) => {
        uploadEntity({
          variables: {
            file,
            fieldName: field.name,
            entityType: field.targetType,
            bundle: field.targetBundle,
          },
        }).then(({ data: { uploadFile } }) => {
          uploadFile.url && setFiles((prev) => [...prev, uploadFile]);
          onChange({ data: [...files, uploadFile] });
          uploadFile.error && setMessage(uploadFile.error);
        });
      });
    }
  };
  const onDelete = (id, idx) => {
    let type = "file";
    if (field.widgetType == "media_library_widget") {
      type = "media";
    }
    deleteEntity({
      variables: {
        id,
        type: type,
      },
    }).then(() => {
      const newFiles = [...files];
      newFiles.splice(idx, 1);
      setFiles(newFiles);
      setValue(field.name, newFiles);
    });
  };
  return (
    <>
      <Controller
        render={({ field: { onChange, ref }, ...props }) => (
          <div tabindex="-1" ref={ref} className="outline-none">
            <InputField
              type="file"
              id="file"
              name="filename"
              inputProps={{
                accept: field.allowedExtensions
                  ? "." + field.allowedExtensions.split(" ").join(",.")
                  : ".jpg,.png",
                multiple: field.cardinality > 1,
              }}
              error={error}
              disabled={files.length == field.cardinality || disabled}
              onChange={async (e) => {
                e.currentTarget.files.length && onUpload(e, onChange);
              }}
              {...props}
            />
          </div>
        )}
        onChange={([, data]) => data}
        name={field.name}
        defaultValue={{ data: files }}
        control={control}
      />
      <div className="file-upload">
        {message && (
          <Alert
            text={<MarkupGenerator string={message} />}
            color="error"
            close={setMessage}
          />
        )}
        {files?.map(({ url, id, fileUri, value }, idx) => {
          const fileName = fileUri?.split("/") || url?.split("/");
          const jpg = fileName[fileName.length - 1]?.split(".").pop();
          if (fileUri || url) {
            return (
              <div key={idx}>
                <Alert
                  text={
                    <a href={url || fileUri} target="_blank">{fileName[fileName.length - 1]}</a>
                  }
                  color="info"
                  close={!disabled ? () => onDelete(id || value, idx) : false}
                  className="alert-file-upload"
                  icon={
                    jpg == "jpg" || jpg == "png" ? (
                      <img style={{ width: "80%" }} src={url || fileUri}></img>
                    ) : <span style={{ width: "80%" }} className="filesvg"></span>
                  }
                />
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </>
  );
};
