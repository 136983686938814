import { Fade } from "@material-ui/core";
import { Alert as A } from "@material-ui/lab";

export const Alert = ({ text, color, close, icon, ...props }) => (
  <>
    {text && (
      <Fade
        in={true}
        style={{
          transitionDelay: "50ms",
        }}
        unmountOnExit
      >
        <A
          variant="outlined"
          color={color}
          severity={color}
          onClose={close ? () => close() : false}
          className="alert"
          icon={icon}
          // action={<Button><img src="close.svg"/></Button>}
          style={{ backgroundColor: "white", alignItems: "center" , borderColor : color == "info" && "var(--title-after)"}}
          {...props}
        >
          <b>{text}</b>
        </A>
      </Fade>
    )}
  </>
);
