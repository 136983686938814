import { useQuery } from "@apollo/client";
import { Divider } from "@material-ui/core";
import { ENTITY_FIELDS } from "../../../graphql/Schemas/Queries/Queries";
import { useFields } from "../../../utils/useFields";
import { DeleteButton } from "../elements";

export const AccordionItem = ({
  id,
  register,
  control,
  unregister,
  onClick,
  parent,
  field,
  disabled,
  errors
}) => {
  const { Fields } = useFields;
  const { data } = useQuery(ENTITY_FIELDS, {
    variables: {
      bundle: field.targetBundle,
      entityType: field.targetType,
      id: typeof id == "number" ? `${id}` : "",
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <div key={id} className="accordion-item">
      <Fields
        data={data?.getEntityFields}
        register={register}
        control={control}
        parent={parent}
        disabled={disabled}
        errors={errors}
      />
      {field.widgetType !== "report_entity_reference_paragraphs" &&
        <DeleteButton
          onClick={() => {
            unregister(parent);
            onClick();
          }}
          text="Törlés"
          type="button"
          style={{ display:"flex",margin: "auto auto 10px" }}
          disabled={disabled}
        />
      }
      <Divider />
    </div>
  );
};
